import { createContext } from "react";

export enum Flow {
  MAIN,
  CHAT,
  REQUEST_DESTINATION,
  REQUEST_PAN,
  SELECT_PAN,
  PREFERENCES,
  RECOMMENDATIONS,
  REQUEST_DESTINATION_RECOMMENDATIONS,
  RESTAURANT_NAVIGATION,
  ENROLLMENT_SELECT_REWARD,
  ENROLLMENT_SELECT_CARD,
  ENROLLMENT_SUCCESS,
  VISA_XB_CAMPAIGN,
  RESTAURANT_DETAILS,
  RESTAURANT_DETAILS_NAVIGATION,
  LOADING,
}

export interface ContextType {
  flow: Flow;
  update: (context: ContextType) => void;
}

export const defaultContext: ContextType = {
  flow: Flow.MAIN,
  update: () => false,
};

export const Context = createContext<ContextType>(defaultContext);
