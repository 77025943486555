import React from "react";

import { PANFormat } from "src/utilities/constants";
import "./style.css";
import { acceptNumbersOnly, mask, unmask } from "./viewModel";

export interface Props {
  card: {
    image: string;
    number: string;
  };
  header: string;
  input: {
    label: string;
    onChange: (value: string) => void;
  };
}

export default function BankCardInput({
  card: { image, number },
  header: header,
  input: { label: inputLabel, onChange },
}: Props): React.ReactElement {
  return (
    <div className="flex flex-col gap-[24px] items-center w-full">
      <label className="font-[600] leading-[29px] text-[20px] text-black">{header}</label>
      <img src={image} className="w-3/10 rounded-lg" />
      <div className="flex flex-col gap-[5px] items-start w-full">
        <label className="leading-[18px] text-[14px] text-paynesGray">{inputLabel}</label>
        <input
          className={
            "card-input-number font-[600] leading-[20px] text-[14px] self-stretch bg-[url('src/assets/images/visa-logo.svg')] bg-no-repeat bg-right bg-auto"
          }
          inputMode="numeric"
          maxLength={PANFormat.length.display}
          onChange={function (e: React.ChangeEvent<HTMLInputElement>) {
            if (e.target.selectionStart !== null) {
              onChange(unmask(e.target.value, e.target.selectionStart, number));
            }
          }}
          onKeyDown={acceptNumbersOnly}
          type="text"
          value={mask(number)}
        />
      </div>
    </div>
  );
}
