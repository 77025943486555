import React, { useState } from "react";

import { Context, ContextType, defaultContext } from "./context";

interface Props {
  children: React.ReactNode;
}

export default function PANProvider({ children }: Props) {
  const [context, setContext] = useState<ContextType>({
    number: defaultContext.number,
    update: (context) => setContext(context),
  });

  return <Context.Provider value={context}>{children}</Context.Provider>;
}
