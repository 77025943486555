import { restaurantRecommendation } from "src/backend/services/restaurantRecommendations";
import { Criteria, Response } from "src/backend/services/restaurantRecommendations/model";
import { Language } from "src/contexts/system/context";
import { coordinates } from "src/models/Coordinates/model";
import { Location, TemplateDetails } from "src/templates";
import { encodingFix, formatOpeningHours, localise } from "src/utilities";
import { RestaurantInfo } from "./type";

export function getOpeningHours(open_hours_en: { [key: string]: string }) {
  const days = [
    open_hours_en.Monday,
    open_hours_en.Tuesday,
    open_hours_en.Wednesday,
    open_hours_en.Thursday,
    open_hours_en.Friday,
    open_hours_en.Saturday,
    open_hours_en.Sunday,
  ];
  return formatOpeningHours(days);
}

export async function getRestaurants(
  pan: string,
  locationId: Location | undefined,
  language: Language,
  template: TemplateDetails
): Promise<RestaurantInfo[]> {
  let latitude = coordinates["hollywood"].latitude;
  let longitude = coordinates["hollywood"].longitude;
  if (locationId) {
    latitude = coordinates[locationId].latitude;
    longitude = coordinates[locationId].longitude;
  }
  const criteria: Criteria = {
    itemsPerPage: 100,
    page: 1,
    pans: [pan],
    searchCriteria: {
      searchBy: "latlng",
      value: [
        {
          name: "latitude",
          value: latitude,
        },
        {
          name: "longitude",
          value: longitude,
        },
        {
          name: "radius",
          value: 10,
        },
      ],
    },
  };
  const result: Response = await restaurantRecommendation(criteria);
  return result.items.map((item, i) => ({
    campaign: i < 2 ? template.restaurantRecommendations.defaultCampaign : "",
    categories: localise(language, [], []).filter((cat) => cat),
    description: encodingFix(localise(language, "", "")),
    id: item.uid,
    opening_hours: [], // TODO: Get back the open hours data once it is ready
    price: item.price_level,
    title: encodingFix(localise(language, item.name, "")),
  }));
}
