import { Auth, Hub } from "aws-amplify";
import React, { useEffect, useState } from "react";

import aws_exports from "src/configurations/aws-exports";
import { getAccessToken } from "src/utilities/amplifyStorage";
import { Context, ContextType, defaultContext } from "./context";

Auth.configure(aws_exports);

interface Props {
  children: React.ReactNode;
}

export const signIn = () => Auth.federatedSignIn({ customProvider: "visa" });

export const signOut = () => Auth.signOut();

export default function AuthProvider({ children }: Props) {
  const [context, setContext] = useState<ContextType>({
    isAuthenticated: defaultContext.isAuthenticated,
    isLoading: defaultContext.isLoading,
    redirect: defaultContext.redirect,
    update: (context) => setContext(context),
  });

  useEffect(() => {
    const updateAuthContext = (redirect: boolean) => {
      const accessToken = getAccessToken();
      setContext((prevContext) => ({
        ...prevContext,
        isAuthenticated: accessToken ? true : false,
        isLoading: false,
        redirect: redirect,
      }));
    };
    const removeListener = Hub.listen("auth", ({ payload: { event } }) => {
      switch (event) {
        case "cognitoHostedUI":
          updateAuthContext(true);
          break;
        case "signIn":
          updateAuthContext(true);
          break;
      }
    });
    updateAuthContext(false);
    return removeListener;
  }, []);

  return <Context.Provider value={context}>{children}</Context.Provider>;
}
