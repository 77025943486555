export enum ErrorCode {
  ERR_CANCELED = "ERR_CANCELED",
}

export enum Route {
  RESTAURANT_DETAILS = "/restaurant-details",
  RESTAURANT_RECOMMENDATION = "/restaurant-recommendation",
  SIGN_IN = "/sign-in",
  SIGN_OUT = "/sign-out",
}

// Last X digits of the mock PAN numbers
export const mockPANs = ["1013", "5048"];
