import React, { useContext } from "react";
import { Context as SystemContext } from "src/contexts/system/context";
import { icons } from "src/assets";

import TagButton from "./TagButton";
import style from "./style.module.css";

export interface Props {
  children: string;
  options: string[];
  sideCaption: string;
  handler: () => void;
}

export default function AbstractCard({
  children,
  options,
  sideCaption,
  handler,
}: Props): React.ReactElement {
  const system = useContext(SystemContext);

  return (
    <div className={style.abstract}>
      <div>
        <h4 lang={system.language}>{sideCaption}</h4>
      </div>
      <div>
        <div className={style.description}>
          <span>
            <img src={icons.apostrophe} alt="Quote" />
          </span>
          <h1>{children}</h1>
          <span>
            <img src={icons.apostrophe} alt="Quote" />
          </span>
        </div>
        <div className={style.taggings}>
          {options.map((option, index) => (
            <TagButton handler={handler} key={`tag-${index}-`} testid={`${index}`}>
              {option}
            </TagButton>
          ))}
        </div>
      </div>
    </div>
  );
}
