import React from "react";

import Option, { Props as OptionProps } from "./Option";
import "./style.css";

export interface Props {
  options: OptionProps[];
}

function renderOptions(options: OptionProps[]): React.ReactElement[] {
  return options.map((option, i) => (
    <div key={`${i}-${Date.now()}`}>
      <Option {...option} />
    </div>
  ));
}

export default function UserFeedbacks({ options }: Props): React.ReactElement {
  return <div className="userFeedback userFeedbackBubble">{renderOptions(options)}</div>;
}
