import React, { useEffect, useRef } from "react";

import close from "src/assets/icons/cross.svg";
import Button from "src/components/Carousal/OptionButton";
import { altTexts, testIDs, config } from "./constants";
import style from "./style.module.css";

export interface Props {
  children?: React.ReactNode;
  closeHandler?: () => void;
  submitButton?: {
    disable?: boolean;
    onPress: () => void;
    title: string;
  };
  title: string;
}

function themeHandler(shade: string) {
  if (document) {
    (document.head.querySelector('meta[name="theme-color"]') as HTMLMetaElement)?.setAttribute(
      "content",
      shade
    );
  }
}

export default function Modal({
  children,
  closeHandler,
  submitButton,
  title,
}: Props): React.ReactElement {
  const modal = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return themeHandler(config.shade);
  });

  function exitHandler() {
    if (modal.current && modal.current.getAnimations?.().length < 2) {
      modal.current.animate?.([{ transform: config.from }, { transform: config.to }], {
        duration: config.timing,
        fill: config.animation as FillMode,
      });
    }

    setTimeout(() => {
      themeHandler(config.unshade);
      closeHandler?.();
    }, config.timing);
  }

  return (
    <div className={style.modal}>
      <div className={`${style.modal} ${style.background}`} onClick={exitHandler} />
      <div className={style.card} ref={modal}>
        <button onClick={() => exitHandler()} data-testid={`${testIDs.closeButton.id}-button`}>
          <img src={close} alt={altTexts.close} data-testid={`${testIDs.closeButton.id}-icon`} />
        </button>
        <div>
          <h2>{title}</h2>
        </div>
        <div>{children}</div>
        {submitButton ? (
          <div>
            <Button
              sizing="large"
              disabled={submitButton.disable}
              selected
              handler={() => {
                exitHandler();
                submitButton.onPress();
              }}
              testid={`${testIDs.buttons.id}-submit-button`}>
              {submitButton.title}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
