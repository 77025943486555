export const testIDs = {
  buttons: {
    id: "pc-filter",
  },
  closeButton: {
    id: "pc-crossout",
  },
  tile: {
    id: "pc-tile",
  },
};

export const altTexts = {
  close: "Close modal",
};

export const config = {
  animation: "forwards",
  from: "translateY(0%)",
  shade: "#B5B7BE",
  timing: 300,
  to: "translateY(100%)",
  unshade: "#F1F4FF",
};
