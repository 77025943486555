import airplane from "./icons/airplane.svg";
import apostrophe from "./icons/apostrophe.svg";
import arrowLeft from "./icons/arrow-left.svg";
import arrowRight from "./icons/arrow-right.svg";
import bill from "./icons/bill.svg";
import calendar from "./icons/calendar.svg";
import car from "./icons/car.svg";
import cardGeneric from "./icons/card-generic.svg";
import cardVerify from "./icons/card-verify.svg";
import caretDown from "./icons/chevron-down.svg";
import caretRight from "./icons/chevron-right.svg";
import cart from "./icons/cart.svg";
import clock from "./icons/clock.svg";
import close from "./icons/close.svg";
import cross from "./icons/cross.svg";
import device from "./icons/device-tv.svg";
import entertainment from "./icons/entertainment.svg";
import fav from "./icons/favourite.svg";
import gift from "./icons/gift.svg";
import hotel from "./icons/hotel.svg";
import line from "./icons/line.svg";
import location from "./icons/location.svg";
import restaurant from "./icons/restaurant.svg";
import merchant from "./icons/merchant.svg";
import mobileApp from "./icons/mobile-app.svg";
import phone from "./icons/phone.svg";
import pin from "./icons/pin.svg";
import repeat from "./icons/repeat.svg";
import reportReady from "./icons/report-ready.svg";
import reservation from "./icons/reservation.svg";
import catering from "./icons/catering.svg";
import ring from "./icons/ring.svg";
import simData from "./icons/sim-data.svg";
import starFill from "./icons/star-fill.svg";
import starOutline from "./icons/star-outline.svg";
import success from "./icons/success.svg";
import ticket from "./icons/ticket.svg";

import apple from "./images/apple.svg";
import campaign from "./images/campaign.svg";
import card from "./images/visa-card.svg";
import defaultCard from "./images/visa-default-card.svg";
import FDNB from "./images/FDNB.svg";
import googlePlay from "./images/google-play.svg";
import landing from "./images/landing.svg";
import landingLogo from "./images/visa-logo-landing.svg";
import login from "./images/login.svg";
import logo from "./images/visa-logo.svg";
import netflix from "./images/netflix.svg";
import restaurants from "./images/restaurant_details.svg";
import starbucks from "./images/starbucks.svg";
import airbnb from "./images/airbnb.svg";
import agoda from "./images/agoda.svg";
import acceptanceMerchants from "./icons/acceptance-merchants.svg";
// Icons from https://productdesignsystem.visa.com/icons
export const icons = {
  acceptanceMerchants,
  airplane,
  apostrophe,
  arrowLeft,
  arrowRight,
  bill,
  calendar,
  car,
  cardGeneric,
  cardVerify,
  caretDown,
  caretRight,
  cart,
  clock,
  close,
  cross,
  device,
  entertainment,
  fav,
  gift,
  hotel,
  line,
  location,
  restaurant,
  merchant,
  mobileApp,
  phone,
  pin,
  repeat,
  reportReady,
  reservation,
  catering,
  ring,
  simData,
  starFill,
  starOutline,
  success,
  ticket,
};

export const images = {
  visa: {
    defaultCard,
    logo,
    card,
    landing,
    landingLogo,
    login,
  },
  bank: {
    FDNB,
  },
  campaign,
  merchant: {
    apple,
    googlePlay,
    starbucks,
    netflix,
    airbnb,
    agoda,
  },
  restaurants,
};
