import React from "react";
import { images } from "src/assets";
import { getMaskedCardNumber } from "src/utilities";
import { BankCard, testIds } from "./constants";
import "./style.css";

export interface Props {
  index: number;
  onClickHandler: (index: number) => void;
  bankCard: BankCard;
  selectType?: undefined | "radio" | "checkbox";
}

export default function AssistantBankCard({
  index,
  onClickHandler,
  bankCard,
  selectType,
}: Props): React.ReactElement {
  return (
    <div className="bankCard" data-testid={`${testIds.card}-${index}`}>
      <div className="leftColumn">
        <div className="row">
          <img src={bankCard.logo} alt="Bank Card Logo" data-testid={`${testIds.logo}-${index}`} />
          <p data-testid={`${testIds.title}-${index}`}>{bankCard.title}</p>
        </div>
        <div className="row">
          <span>
            <img
              src={images.visa.card}
              alt="VISA Logo"
              data-testid={`${testIds.visaLogo}-${index}`}
            />
          </span>
          {bankCard.pan && (
            <span
              className="font-[400] non-chinese text-[16px] text-black"
              data-testid={`${testIds.maskedPan}-${index}`}>
              {getMaskedCardNumber(bankCard.pan)}
            </span>
          )}
        </div>
      </div>
      <div className="rightColumn">
        <input
          className={`${selectType ? selectType : "radio"}`}
          type={`${selectType ? selectType : "radio"}`}
          checked={bankCard.checked}
          onChange={() => onClickHandler(index)}
          data-testid={`${testIds.radio}-${index}`}
        />
      </div>
    </div>
  );
}
