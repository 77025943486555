import { Language } from "src/configurations";
import { chatbot } from "src/models/Chatbot";

export function getChat(language: Language, current: number) {
  return chatbot.chat(language, current);
}

export function resetChat() {
  chatbot.reset();
}
