import { ContextType } from "src/contexts/preferences/context";

export enum CATEGORIES {
  CUISINE = "cuisine",
  DISTANCE = "distances",
  FILTER = "filters",
}

export type display = { label: string; value: string; selected: boolean };

export type grouping = CATEGORIES.CUISINE | CATEGORIES.DISTANCE | CATEGORIES.FILTER;

export interface Preferences {
  [CATEGORIES.CUISINE]: display[];
  [CATEGORIES.DISTANCE]: { key: string; options: display[] }[];
  [CATEGORIES.FILTER]: { key: string; options: display[] }[];
}

interface Selections {
  [CATEGORIES.DISTANCE]: { name: string; value: string }[];
  [CATEGORIES.FILTER]: { name: string; value: string[] }[];
}

interface Options {
  cuisine: string[];
  distance: { key: string; options: string[] }[];
  filter: { key: string; options: string[] }[];
}

export function displayPreferences(options: Options, selections: ContextType): Preferences {
  const _filter: { key: string; options: display[] }[] = [];
  const _distance: { key: string; options: display[] }[] = [];

  const selectedOnes: { [key: string]: boolean } = {};

  selections.filters.forEach((filter) => {
    filter.value.forEach((value) => {
      selectedOnes[`${filter.name} ${value}`] = true;
    });
  });

  selections.distances.forEach((dist) => {
    selectedOnes[`${dist.name} ${dist.value}`] = true;
  });

  options.filter.forEach((item) => {
    _filter.push({
      key: item.key,
      options: item.options.map((option) => {
        return {
          label: option,
          value: option,
          selected: selectedOnes[`${item.key} ${option}`] ? true : false,
        };
      }),
    });
  });

  options.distance.forEach((item) => {
    _distance.push({
      key: item.key,
      options: item.options.map((option) => {
        return {
          label: option,
          value: option,
          selected: selectedOnes[`${item.key} ${option}`] ? true : false,
        };
      }),
    });
  });

  return {
    distances: _distance,
    filters: _filter,
    cuisine: options.cuisine.map((option) => {
      return {
        label: option,
        value: option,
        selected: selectedOnes[`${CATEGORIES.CUISINE} ${option}`],
      };
    }),
  };
}

export function submitSelections(options: Preferences): Selections {
  type category = { name: string; value: string[] };
  const results: Selections = {
    distances: [],
    filters: [],
  };

  const res: category = {
    name: CATEGORIES.CUISINE,
    value: [],
  };

  options.cuisine.forEach((option) => {
    if (option.selected) {
      res.value.push(option.value);
    }
  });

  if (res.value) {
    results.filters.push(res);
  }

  options.filters.forEach((item) => {
    const res: category = {
      name: item.key,
      value: [],
    };

    item.options.forEach((option) => {
      if (option.selected) {
        res.value.push(option.value);
      }
    });

    if (res.value.length > 0) {
      results.filters.push(res);
    }
  });

  options.distances.forEach((item) => {
    const res: { name: string; value: string } = {
      name: item.key,
      value: "",
    };

    item.options.forEach((option) => {
      if (option.selected) {
        res.value = option.value;
      }
    });

    if (res.value) {
      results.distances.push(res);
    }
  });

  return results;
}

export function getPreferences(): Options {
  return {
    cuisine: [
      "All Food",
      "Asian Cuisine",
      "Chinese Cuisine",
      "Chinese Noodles",
      "Cantonese Cuisine",
      "Chinese Hot Pot",
      "Sichuan Cuisine",
      "Dim Sum",
      "Taiwan Cuisine",
      "Japanese Cuisine",
      "Izakaya",
      "Teppanyaki",
      "Sushi",
      "Thai Cuisine",
      "Sukiyaki & Shabu",
      "Korean Cuisine",
      "Korean BBQ",
    ],
    distance: [
      {
        key: CATEGORIES.DISTANCE,
        options: ["< 500 m", "500m~2km", "2km~5km", "> 5km"],
      },
    ],
    filter: [
      {
        key: "price range",
        options: ["$", "$$", "$$$", "$$$$"],
      },
      {
        key: "atmosphere",
        options: ["Family", "Dating", "Solo", "Group", "Upscale", "Retro", "Live Performance"],
      },
      {
        key: "service",
        options: [
          "Breakfast",
          "Brunch",
          "Lunch",
          "Dinner",
          "Night Meal",
          "Vegan",
          "Halal",
          "Organic",
          "All you can drink",
          "All you can eat",
          "Dine in",
          "Takeout",
          "Outdoor",
          "Drive through",
        ],
      },
      {
        key: "payment",
        options: ["Credit Card", "Mobile NFC"],
      },
      {
        key: "reservation",
        options: ["Reservations accepted", "Reservations required"],
      },
    ],
  };
}
