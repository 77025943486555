import React from "react";

export interface Props {
  text: string;
  onPress?: () => void;
}

export default function Option({ onPress, text }: Props): React.ReactElement {
  return (
    <button
      className="bg-white border border-primary border-solid px-[10px] py-[11px] rounded-[40px] text-black"
      onClick={onPress}>
      {text}
    </button>
  );
}
