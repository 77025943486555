import mockResponseLA11013 from "src/assets/mocks/LA_1_1013.json";
import mockResponseLA15048 from "src/assets/mocks/LA_1_5048.json";
import mockResponseLA21013 from "src/assets/mocks/LA_2_1013.json";
import mockResponseLA25048 from "src/assets/mocks/LA_2_5048.json";
import mockResponseLA31013 from "src/assets/mocks/LA_3_1013.json";
import mockResponseLA35048 from "src/assets/mocks/LA_3_5048.json";
import mockResponseLA41013 from "src/assets/mocks/LA_4_1013.json";
import mockResponseLA45048 from "src/assets/mocks/LA_4_5048.json";
import mockResponseSG11013 from "src/assets/mocks/SG_1_1013.json";
import mockResponseSG15048 from "src/assets/mocks/SG_1_5048.json";
import mockResponseSG21013 from "src/assets/mocks/SG_2_1013.json";
import mockResponseSG25048 from "src/assets/mocks/SG_2_5048.json";
import { mockPANs } from "src/backend/constants";
import { getLocationName } from "src/models/Coordinates/Coordinate";
import { PANFormat } from "src/utilities/constants";
import { Request } from "./model";

export function getMock(data: Request) {
  const criteria = data.attributeMap.searchCriteria.value;
  const lat = criteria.find((value) => value.name === "latitude")?.value as number;
  const lng = criteria.find((value) => value.name === "longitude")?.value as number;
  const location = getLocationName({
    latitude: lat,
    longitude: lng,
  });
  const pan = data.attributeMap.pans[0];
  const id = pan.slice(pan.length - PANFormat.segment.length);
  switch (location) {
    case "beverlyHills":
      if (id === mockPANs[0]) {
        return mockResponseLA21013;
      } else {
        return mockResponseLA25048;
      }
    case "chinatown":
      if (id === mockPANs[0]) {
        return mockResponseLA31013;
      } else {
        return mockResponseLA35048;
      }
    case "hollywood":
      if (id === mockPANs[0]) {
        return mockResponseLA11013;
      } else {
        return mockResponseLA15048;
      }
    case "universalStudios":
      if (id === mockPANs[0]) {
        return mockResponseLA41013;
      } else {
        return mockResponseLA45048;
      }
    case "merlion":
    case "seaAquarium":
    case "universalStudiosSingapore":
      if (id === mockPANs[0]) {
        return mockResponseSG21013;
      } else {
        return mockResponseSG25048;
      }
    case "visaRobinsonRoad":
      if (id === mockPANs[0]) {
        return mockResponseSG11013;
      } else {
        return mockResponseSG15048;
      }
  }
}

export function shouldMock(data: Request) {
  const pan = data.attributeMap.pans[0];
  if (pan.length === PANFormat.length.value) {
    return false;
  }
  const id = pan.slice(pan.length - PANFormat.segment.length);
  return mockPANs.includes(id);
}
