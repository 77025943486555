import React from "react";

import Option, { Props as OptionProps } from "./Option";
import "./style.css";
import { getChatDate } from "src/utilities";

export interface Props {
  date?: Date;
  message: string;
  options: OptionProps[];
}

function renderOptions(options: OptionProps[]): React.ReactElement[] {
  return options.map((option, i) => <Option {...option} key={`${i}-${Date.now()}`} />);
}

export default function AssistantListSelectionChatBubble({
  date,
  message,
  options,
}: Props): React.ReactElement {
  return (
    <div className="flex flex-col gap-[12px]">
      <label className="font-[400] text-[12px] text-black">{getChatDate(date ?? new Date())}</label>
      <div className="assistantChatBubble bg-white flex flex-col gap-[12px] px-[24px] pt-[12px] rounded-br-[24px] rounded-t-[24px] shadow-[0_4px_20px_rgba(0,0,0,0.1)]">
        <label className="font-[400] leading-[21px] text-[14px] text-black">{message}</label>
        <div>{renderOptions(options)}</div>
      </div>
    </div>
  );
}
