import React from "react";
import Selection, { Props as ButtonProps } from "./Selection";

import { icons } from "src/assets";
import { getChatDate } from "src/utilities";
import "./style.css";

export interface Props {
  date?: Date;
  message: {
    noDestination: string;
    hasDestination: string;
  };
  dropDownProps: DropDownProps;
  buttonOptions: ButtonProps[];
}

export interface DropDownProps {
  message: string;
  value: string;
  placeholder: string;
  onPress: () => void;
}

function renderButtonOptions(options: ButtonProps[]): React.ReactElement[] {
  return options.map((option, i) => (
    <div key={`${i}-${Date.now()}`}>
      <Selection {...option} />
    </div>
  ));
}

export default function BubbleWithDropDownButtonSelection({
  date,
  message,
  dropDownProps,
  buttonOptions,
}: Props): React.ReactElement {
  const destinationButtonStyle =
    dropDownProps.value === dropDownProps.placeholder
      ? "locationButton max-w-full max-h-[30px] text-[rgba(0,0,0,0.2)] text-start flex items-center gap-1 py-1 font-bold text-[14px] leading-[21px] rounded-[100px]"
      : "locationButton max-w-full max-h-[30px] text-primary text-start flex items-center gap-1 py-1 font-bold text-[14px] leading-[21px] rounded-[100px]";
  return (
    <div className="text-black flex flex-col items-start gap-3">
      <label className=" text-xs text-black">{getChatDate(date ?? new Date())}</label>
      <div className="assistantChatBubble bg-white flex flex-col items-start rounded-br-[24px] rounded-t-[24px] shadow-[0_4px_20px_rgba(0,0,0,0.1)] px-6 py-3 gap-3">
        <label className="leading-[21px] text-[14px]">
          {dropDownProps.value === dropDownProps.placeholder
            ? message.noDestination
            : message.hasDestination}
        </label>
        <div className="flex flex-col gap-3">
          <label className="text-black font-bold text-[14px] leading-[21px] items-center capitalize flex">
            {dropDownProps.message}
          </label>
          <button className={destinationButtonStyle} onClick={dropDownProps.onPress}>
            {dropDownProps.value}
            <img className="w-[18px] h-[18px]" src={icons.caretDown}></img>
          </button>
        </div>
        {dropDownProps.value !== dropDownProps.placeholder ? (
          <div className="flex flex-wrap items-start gap-2">
            {renderButtonOptions(buttonOptions)}
          </div>
        ) : null}
      </div>
    </div>
  );
}
