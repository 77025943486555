import React, { useContext, useEffect, useState } from "react";

import { signOut } from "src/contexts/auth";
import ChatHeader from "src/components/ChatHeader";
import VirtualAssistant from "src/components/VirtualAssistant";
import { Context as ChatContext, defaultContext as defaultChat } from "src/contexts/chat/context";
import {
  Context as EnrollmentContext,
  defaultContext as defaultEnrollment,
} from "src/contexts/enrollment/context";
import { Context as NavigationContext, Flow } from "src/contexts/navigation/context";
import { Context as PANContext, defaultContext as defaultPAN } from "src/contexts/PAN/context";
import {
  Context as PreferencesContext,
  defaultContext as defaultPreferences,
} from "src/contexts/preferences/context";
import {
  Context as RestaurantContext,
  defaultContext as defaultRestaurant,
} from "src/contexts/restaurant/context";
import {
  Context as SystemContext,
  defaultContext as defaultSystem,
} from "src/contexts/system/context";
import { render } from "src/renderer/Chat";
import Template from "src/templates";
import "./style.css";
import { getChat, resetChat } from "./viewModel";

export default function Chat(): React.ReactElement {
  const chat = useContext(ChatContext);
  const enrollment = useContext(EnrollmentContext);
  const navigation = useContext(NavigationContext);
  const pan = useContext(PANContext);
  const preferences = useContext(PreferencesContext);
  const restaurant = useContext(RestaurantContext);
  const system = useContext(SystemContext);
  const template = Template(system.language);

  const [messages, setMessages] = useState(getChat(system.language, chat.current));

  function renderMessages() {
    return messages.map((message, i) => render(message, i));
  }

  useEffect(() => {
    setTimeout(() => setMessages(getChat(system.language, chat.current)), 500);
  }, [chat, system.language]);

  useEffect(() => {
    const component = document.getElementById("chatBody");
    if (component) {
      component.scrollTo({
        behavior: "smooth",
        left: 0,
        top: component.scrollHeight + component.scrollTop,
      });
    }
  }, [messages]);

  return (
    <>
      <div className="bg-background">
        <ChatHeader
          title={template.chat.header}
          backHandler={async () => {
            try {
              await signOut();
              resetChat();
              chat.update({ ...defaultChat, update: chat.update });
              enrollment.update({ ...defaultEnrollment, update: enrollment.update });
              pan.update({ ...defaultPAN, update: pan.update });
              preferences.update({ ...defaultPreferences, update: preferences.update });
              restaurant.update({ ...defaultRestaurant, update: restaurant.update });
              navigation.update({ ...navigation, flow: Flow.MAIN });
              system.update({ ...defaultSystem, update: system.update });
            } catch (_error) {
              // TODO: Update the UI to show the failure to sign the user out
            }
          }}
        />
      </div>
      <div className="chatBody bg-background" id="chatBody">
        <div className="chatContent">
          <VirtualAssistant
            name={template.chat.profile.name}
            rating={5}
            role={template.chat.profile.role}
          />
          <div>{renderMessages()}</div>
        </div>
      </div>
    </>
  );
}
