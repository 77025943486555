import React from "react";

import "./style.css";

export interface Props {
  disabled?: boolean;
  onPress?: () => void;
  text: string;
}

export default function Option({ disabled, onPress, text }: Props): React.ReactElement {
  return (
    <button
      className="assistantChatBubbleListOption text-primary"
      disabled={disabled}
      onClick={onPress}>
      {text}
    </button>
  );
}
