export type BankCard = {
  checked: boolean;
  logo: string;
  title: string;
  pan: string;
};

export const testIds = {
  card: "bank-card",
  logo: "bank-card-logo",
  title: "bank-card-title",
  visaLogo: "bank-card-visa-logo",
  maskedPan: "bank-card-masked-pan",
  radio: "bank-card-radio-btn",
};
