import { createContext } from "react";

export enum TYPES {
  MAPS,
  PHONE,
  WEBSITE,
}

export type Location = {
  latitude: number;
  longitude: number;
  address: string;
};

export interface ContextType {
  id: string;
  type: TYPES;
  url: string;
  phone: string;
  location: Location;
  update: (context: ContextType) => void;
}

export const defaultContext: ContextType = {
  id: "",
  type: TYPES.MAPS,
  location: {
    latitude: 0,
    longitude: 0,
    address: "",
  },
  update: () => false,
  url: "",
  phone: "",
};

export const Context = createContext<ContextType>(defaultContext);
