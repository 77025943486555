import React from "react";

import style from "./style.module.css";

export interface Props {
  navs?: string[];
  selected?: number | string;
  selector?: (choice: number) => void;
  children: React.ReactNode;
}

export default function VerticalNav({
  navs = [],
  selected = 0,
  selector = () => null,
  children,
}: Props): React.ReactElement {
  return (
    <div className={style.navigation}>
      {navs.length < 1 ? null : (
        <div className={style.items}>
          {navs.map((item, index) => (
            <button
              onClick={() => selector(index)}
              className={selected !== index ? style.unselected : style.selected}
              key={`choice-${index}-`}>
              {item}
            </button>
          ))}
        </div>
      )}
      <div className={navs.length > 0 ? style.partial : style.full}>{children}</div>
    </div>
  );
}
