import moment from "moment";

import { Language } from "src/configurations";
import { Destination } from "src/contexts/preferences/context";
import { TemplateDetails } from "src/templates";
import { PANFormat, regex } from "./constants";

export function encodingFix(text: string): string {
  return text.replaceAll("&amp;", "&").replaceAll("�", "'");
}

export function formatOpeningHours(days: string[]) {
  return days.map(function (day) {
    if (regex.timeRange.to.test(day)) {
      return day.replaceAll("to", "-");
    }
    return day;
  });
}

export function getChatDate(date: Date): string {
  return (
    date.getFullYear() +
    "." +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "." +
    ("0" + date.getDate()).slice(-2) +
    "\xa0\xa0\xa0" +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2)
  );
}

export function getDestinationName(
  template: TemplateDetails,
  placeholder: string,
  destination?: Destination
): string {
  if (destination) {
    const city = template.cities[destination.city];
    if (destination.location.zh) {
      if (destination.location.zh.includes("办公室")) {
        return `${city}-${destination.location.zh}`;
      }
      return `${city}-${destination.location.zh} ${destination.location.en}`;
    } else {
      return `${city} - ${destination.location.en}`;
    }
  } else {
    return placeholder;
  }
}

export function getMaskedCardNumber(text: string): string {
  return (
    text.slice(0, PANFormat.segment.length).replace(regex.digit, "•") +
    " " +
    text.slice(text.length - PANFormat.segment.length)
  );
}

export function getLocalTime(city: string | undefined, template: TemplateDetails) {
  if (city === template.cities.losAngeles) {
    return (
      ("0" + moment().tz("America/Los_Angeles").hour()).slice(-2) +
      ":" +
      ("0" + moment().tz("America/Los_Angeles").minute()).slice(-2) +
      ")"
    );
  } else {
    return (
      ("0" + moment().tz("Asia/Singapore").hour()).slice(-2) +
      ":" +
      ("0" + moment().tz("Asia/Singapore").minute()).slice(-2) +
      ")"
    );
  }
}

export function getOpeningHours(dayOfWeek: number, openingHours: string[]) {
  if (openingHours.length === 7) {
    return openingHours[(dayOfWeek + 6) % 7];
  }
  return "Closed";
}

export function localise<T>(language: Language, en: T, cn: T): T {
  switch (language) {
    case "en":
      return en;
    case "zh":
      return cn;
  }
}

export function checkOpen(localTime: string, openingHours: string) {
  if (openingHours === "Open 24 hours") {
    return true;
  } else if (openingHours === "Closed") {
    return false;
  } else if (openingHours.includes(",")) {
    const times = openingHours.split(",");
    for (let i = 0; i < times.length; i++) {
      const time = times[i].replaceAll(" ", "");
      const startTimeMins = parseInt(time.substring(0, 2)) * 60 + parseInt(time.substring(3, 5));
      const endTimeMins = parseInt(time.substring(6, 8)) * 60 + parseInt(time.substring(9));
      const localTimeMins =
        parseInt(localTime.substring(0, 2)) * 60 + parseInt(localTime.substring(3, 5));
      if (endTimeMins > startTimeMins) {
        if (startTimeMins <= localTimeMins && localTimeMins <= endTimeMins) {
          return true;
        }
      } else {
        if (!(endTimeMins < localTimeMins && localTimeMins < startTimeMins)) {
          return true;
        }
      }
    }
    return false;
  } else {
    const startTimeMins =
      parseInt(openingHours.substring(0, 2)) * 60 + parseInt(openingHours.substring(3, 5));
    const endTimeMins =
      parseInt(openingHours.substring(8, 10)) * 60 + parseInt(openingHours.substring(11));
    const localTimeMins =
      parseInt(localTime.substring(0, 2)) * 60 + parseInt(localTime.substring(3, 5));
    if (endTimeMins > startTimeMins) {
      return startTimeMins <= localTimeMins && localTimeMins <= endTimeMins;
    } else {
      return !(endTimeMins < localTimeMins && localTimeMins < startTimeMins);
    }
  }
}
