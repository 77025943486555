import React, { useState } from "react";

import { Context, ContextType, defaultContext } from "./context";

interface Props {
  children: React.ReactNode;
}

export default function PreferenceProvider({ children }: Props) {
  const [context, setContext] = useState<ContextType>({
    filters: defaultContext.filters,
    distances: defaultContext.distances,
    destination: defaultContext.destination,
    update: (context) => setContext(context),
  });

  return <Context.Provider value={context}>{children}</Context.Provider>;
}
