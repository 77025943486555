import React from "react";

import style from "./style.module.css";

export interface Props {
  icon: string;
  title: string;
  borderTop?: boolean;
  borderBottom?: boolean;
  content?: string[] | string | null;
  children?: React.ReactNode;
}

export default function ListTile({
  icon,
  title,
  borderTop = false,
  borderBottom = false,
  content,
  children,
}: Props): React.ReactElement {
  return (
    <div className={style.listtile}>
      {!borderTop ? null : <hr />}
      <div>
        <div className={style.abstracts}>
          <div>
            <img src={require(`src/assets/icons/${icon}`)} alt={icon} />
          </div>
          <p>{title}</p>
        </div>
        {!content ? null : (
          <div className={style.listing}>
            <div className={style.tags}>
              {Array.isArray(content) ? (
                content.map((tag, ind) => <button key={`tagging-${ind}-`}>{tag}</button>)
              ) : (
                <p className={style.content}>{content}</p>
              )}
            </div>
            {!children ? null : <div className={style.actions}>{children}</div>}
          </div>
        )}
      </div>
      {!borderBottom ? null : <hr />}
    </div>
  );
}
