import { Location } from "src/templates";
import { coordinates } from "./model";

export interface Coordinate {
  latitude: number;
  longitude: number;
}

export function getLocationName(coordinate: Coordinate): Location {
  for (const location in coordinates) {
    const data = coordinates[location as Location];
    if (data.latitude === coordinate.latitude && data.longitude === coordinate.longitude) {
      return location as Location;
    }
  }
  return "hollywood";
}
