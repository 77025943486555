import React, { useState } from "react";

import { Context, ContextType, defaultContext } from "./context";

interface Props {
  children: React.ReactNode;
}

export default function RestaurantProvider({ children }: Props) {
  const [context, setContext] = useState<ContextType>({
    type: defaultContext.type,
    url: defaultContext.url,
    id: defaultContext.id,
    phone: defaultContext.phone,
    location: defaultContext.location,
    update: (context) => setContext(context),
  });

  return <Context.Provider value={context}>{children}</Context.Provider>;
}
