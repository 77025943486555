import React from "react";
import { getChatDate } from "src/utilities/index";
import "./style.css";

export interface Props {
  date: Date;
  message: string;
  style?: string;
}

export default function UserChatBubble({ date, message, style }: Props): React.ReactElement {
  return (
    <div className="flex flex-col gap-[12px] items-end">
      <div className="flex flex-col">
        <label className="font-[400] leading-[16px] text-[12px] text-black">
          {getChatDate(date)}
        </label>
      </div>
      <div className="bg-primary flex flex-col max-w-[80%] px-[24px] py-[12px] rounded-bl-[24px] rounded-t-[24px] shadow-[0_4px_20px_rgba(0,0,0,0.1)]">
        <label className={style ? style : `font-[400] leading-[20px] text-[16px] text-white`}>
          {message}
        </label>
      </div>
    </div>
  );
}
