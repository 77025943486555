import React from "react";

import "./style.css";

export interface Props {
  children: string;
  handler?: () => void;
  testid?: string;
}

export default function TagButton({ children, handler, testid }: Props): React.ReactElement {
  return (
    <button className="tagButton" onClick={handler} data-testid={testid}>
      <p>{children}</p>
    </button>
  );
}
