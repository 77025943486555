import { Details } from "./type";

export const listicle = {
  icons: {
    bill: "bill.svg",
    address: "pin.svg",
    reservation: "reservation.svg",
    atmosphere: "merchant.svg",
    service: "favourite.svg",
    payment: "favourite.svg",
  },
};

export const altTexts = {
  back: "Back",
  dropdown: "Dropdown",
  location: "Location",
  open: "open",
  time: "Time",
};

export const emptyDetails: Details = {
  address: "",
  categories: [],
  description: "",
  location: {
    lat: 0,
    lng: 0,
    timezone: "",
  },
  name: "",
  imageURL: "",
  opening_hours: [],
  payments: [],
  phone: "",
  price_level: "",
  reservations: [],
  services: [],
  vibes: [],
  website: "",
};
