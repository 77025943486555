import React, { useContext, useEffect } from "react";

import { Context as ChatContext } from "src/contexts/chat/context";
import AssistantChatBubble from "../AssistantChatBubble";
import UserChatBubble from "../UserChatBubble";
import UserFeedbacks from "../UserFeedbacks";

import "./style.css";
import { MessageType } from "../../renderer/MessageType";

export interface Props {
  date?: Date;
  feedbacks: string[];
  message: string;
}

export default function Greeting({ date, feedbacks, message }: Props): React.ReactElement {
  const chat = useContext(ChatContext);
  useEffect(() => {
    if (chat.current === MessageType.GREET + 0.5) {
      setTimeout(() => chat.update({ ...chat, current: chat.current + 0.5 }), 500);
    }
  }, [chat]);
  return (
    <div className="h-[calc(100%-20rem)] w-[100%] flex flex-col flex-grow justify-between">
      <AssistantChatBubble date={date} message={message} />
      {chat.greeting && (
        <UserChatBubble date={chat.greeting.date} message={chat.greeting.feedback} />
      )}
      {chat.greeting === undefined && (
        <UserFeedbacks
          options={feedbacks.map((feedback) => ({
            onPress: () => {
              setTimeout(
                () =>
                  chat.update({
                    ...chat,
                    current: chat.current + 0.5,
                    greeting: { date: new Date(), feedback },
                  }),
                500
              );
            },
            text: feedback,
          }))}
        />
      )}
    </div>
  );
}
