import { createContext } from "react";

export interface ContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  redirect: boolean;
  update: (context: ContextType) => void;
}

export const defaultContext: ContextType = {
  isAuthenticated: false,
  isLoading: true,
  redirect: false,
  update: () => false,
};

export const Context = createContext<ContextType>(defaultContext);
