import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import ChatProvider from "./contexts/chat";
import EnrollmentProvider from "./contexts/enrollment";
import NavigationProvider from "./contexts/navigation";
import PANProvider from "./contexts/PAN";
import PreferencesProvider from "./contexts/preferences";
import RestaurantProvider from "./contexts/restaurant";
import SystemProvider from "./contexts/system";
import AuthProvider from "./contexts/auth";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <NavigationProvider>
        <ChatProvider>
          <PANProvider>
            <PreferencesProvider>
              <RestaurantProvider>
                <EnrollmentProvider>
                  <SystemProvider>
                    <App />
                  </SystemProvider>
                </EnrollmentProvider>
              </RestaurantProvider>
            </PreferencesProvider>
          </PANProvider>
        </ChatProvider>
      </NavigationProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
