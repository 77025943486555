import React, { useContext, useState } from "react";

import AssistantBankCardList from "src/components/AssistantBankCardList";
import Modal from "src/components/Modal";
import { Context as ChatContext } from "src/contexts/chat/context";
import { Context as NavigationContext, Flow } from "src/contexts/navigation/context";
import { Context as PANContext } from "src/contexts/PAN/context";
import { Context as SystemContext } from "src/contexts/system/context";
import Template from "src/templates";
import "./style.css";

export default function PANSelect(): React.ReactElement {
  const chat = useContext(ChatContext);
  const navigation = useContext(NavigationContext);
  const pan = useContext(PANContext);
  const system = useContext(SystemContext);
  const [selectedPan, setSelectedPan] = useState<string>();
  const template = Template(system.language);

  function onClose() {
    setTimeout(() => {
      navigation.update({ ...navigation, flow: Flow.CHAT });
      if (selectedPan) {
        pan.update({ ...pan, number: selectedPan });
        system.update({ ...system, shouldMockAPI: true });
        setTimeout(() => chat.update({ ...chat, current: chat.current + 0.5 }), 500);
      }
    }, 500);
  }

  return (
    <Modal
      submitButton={{
        disable: !selectedPan,
        onPress: onClose,
        title: template.actions.confirm,
      }}
      title={template.requestPAN.headerChoice}
      closeHandler={() => navigation.update({ ...navigation, flow: Flow.CHAT })}>
      <AssistantBankCardList setSelectedPan={setSelectedPan} />
    </Modal>
  );
}
