import React, { useContext, useEffect, useState } from "react";

import { Context, ContextType, Flow, defaultContext } from "./context";
import { Context as AuthContext } from "src/contexts/auth/context";

interface Props {
  children: React.ReactNode;
}

export default function NavigationProvider({ children }: Props) {
  const auth = useContext(AuthContext);
  const [context, setContext] = useState<ContextType>({
    flow: defaultContext.flow,
    update: (context) => setContext(context),
  });
  useEffect(() => {
    let flow: Flow;
    if (auth.isLoading) {
      flow = Flow.LOADING;
    } else if (auth.isAuthenticated) {
      if (auth.redirect) {
        flow = Flow.CHAT;
      } else {
        flow = Flow.MAIN;
      }
    } else {
      flow = Flow.MAIN;
    }
    setContext((prevContext) => ({
      ...prevContext,
      flow: flow,
    }));
  }, [auth]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
}
