import { images } from "src/assets";
import { Language } from "src/contexts/system/context";
import Template from "src/templates";
import { BankCard } from "./constants";

export function getBankCard(language?: Language, bankCard?: BankCard): BankCard {
  const template = Template(language);
  const defaultBankCard: BankCard = {
    checked: false,
    logo: images.bank.FDNB,
    title: template.bank.fdnb,
    pan: "1234 1234 1234 1234",
  };
  return { ...defaultBankCard, ...bankCard };
}
