const config = require("../configurations").default;
const storage = require("../utilities/amplifyStorage").authStorage;
 
console.log(config);

const awsmobile = {
  aws_cognito_region: config.aws.cognito.cognitoRegion,
  aws_project_region: config.aws.cognito.projectRegion,
  aws_user_pools_id: config.aws.cognito.userPool.id,
  aws_user_pools_web_client_id: config.aws.cognito.userPool.clientId,
  oauth: {
    domain: config.aws.cognito.domain,
    scope: ["openid"],
    redirectSignIn: config.aws.cognito.redirectURL.signIn,
    redirectSignOut: config.aws.cognito.redirectURL.signOut,
    responseType: "code",
  },
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL", "GIVEN_NAME", "FAMILY_NAME"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  storage,
};
 
export default awsmobile;
