import { Auth } from "aws-amplify";
import axios, { InternalAxiosRequestConfig } from "axios";

import config from "src/configurations";
import { getAccessToken } from "src/utilities/amplifyStorage";
import { encrypt } from "src/utilities/crypt";
import { ErrorCode, Route } from "./constants";
import {
  getMock as getMockRestaurantDetails,
  shouldMock as shouldMockRestaurantDetails,
} from "./services/restaurantDetails/index.mock";
import { Request as RestaurantDetailsRequest } from "./services/restaurantDetails/model";
import {
  getMock as getMockRestaurantRecommendations,
  shouldMock as shouldMockRestaurantRecommendations,
} from "./services/restaurantRecommendations/index.mock";
import { Request as RestaurantRecommendationsRequest } from "./services/restaurantRecommendations/model";

const axiosInstance = axios.create({
  baseURL: config.app.host,
  headers: {
    "Content-Security-Policy": "default-src self",
    "Content-Type": "application/json",
    "Strict-Transport-Security": "max-age=63072000; includeSubDomains; preload",
    "X-Frame-Options": "DENY",
    "X-Xss-Protection": "1; mode=block",
  },
  timeout: 10 * 1000,
});

axiosInstance.interceptors.request.use(async function (config) {
  try {
    config.headers.Authorization = "Bearer " + getAccessToken();
    return Promise.resolve(config);
  } catch {
    Auth.signOut();
    return Promise.reject(config);
  }
});

axiosInstance.interceptors.request.use(requestInterceptor);

axiosInstance.interceptors.response.use(
  function (response) {
    if (response.data) {
      return response.data;
    }
    return response;
  },
  function (error) {
    switch (error.code) {
      case ErrorCode.ERR_CANCELED:
        if (error.config) {
          return getMock(error.config.url, error.config.data, error);
        } else {
          return Promise.reject(error);
        }
      default:
        return Promise.reject(error);
    }
  }
);

function requestInterceptor(axioConfig: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
  if (axioConfig.url && axioConfig.url.endsWith(Route.RESTAURANT_DETAILS)) {
    const abortController = new AbortController();
    if (shouldMockRestaurantDetails(axioConfig.data as RestaurantDetailsRequest)) {
      abortController.abort();
    }
    return {
      ...axioConfig,
      signal: abortController.signal,
    };
  }
  if (axioConfig.url && axioConfig.url.endsWith(Route.RESTAURANT_RECOMMENDATION)) {
    const abortController = new AbortController();
    if (shouldMockRestaurantRecommendations(axioConfig.data as RestaurantRecommendationsRequest)) {
      abortController.abort();
    } else {
      const encrytedPayload = encrypt(JSON.stringify(axioConfig.data));
      const wrapPayload = { enc: JSON.parse(encrytedPayload) };
      axioConfig.data = wrapPayload;
    }
    return {
      ...axioConfig,
      signal: abortController.signal,
    };
  }
  return axioConfig;
}

function getMock(url: string, data: unknown, error: unknown) {
  if (url.endsWith(Route.RESTAURANT_DETAILS)) {
    try {
      return getMockRestaurantDetails(data as RestaurantDetailsRequest);
    } catch (err) {
      return err;
    }
  }
  if (url.endsWith(Route.RESTAURANT_RECOMMENDATION)) {
    try {
      return getMockRestaurantRecommendations(data as RestaurantRecommendationsRequest);
    } catch (err) {
      return err;
    }
  }
  return error;
}

export default axiosInstance;
