import { images } from "src/assets";
import { Rewards } from "./constants";
import { TemplateDetails } from "src/templates";

export function getGiftCardList(template: TemplateDetails): Rewards[] {
  return [
    {
      checked: false,
      logo: images.merchant.apple,
      title: template.enrollment.appleGiftCard,
    },
    { checked: false, logo: images.merchant.googlePlay, title: template.enrollment.googleGiftCard },
    {
      checked: false,
      logo: images.merchant.starbucks,
      title: template.enrollment.starbucksGiftCard,
    },
    {
      checked: false,
      logo: images.merchant.netflix,
      title: template.enrollment.netflixGiftCard,
    },
    {
      checked: false,
      logo: images.merchant.airbnb,
      title: template.enrollment.airbnbCoupon,
    },
    {
      checked: false,
      logo: images.merchant.agoda,
      title: template.enrollment.agodaCoupon,
    },
  ];
}
