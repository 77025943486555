import React, { useContext, useState, useEffect } from "react";

import { icons } from "src/assets";
import ConfirmButton from "src/components/Carousal/OptionButton";
import Header from "src/components/Header";
import { Context as ChatContext } from "src/contexts/chat/context";
import { Context as NavigationContext, Flow } from "src/contexts/navigation/context";
import { Context as PANContext } from "src/contexts/PAN/context";
import { Context as PreferencesContext } from "src/contexts/preferences/context";
import { Context as RestaurantContext } from "src/contexts/restaurant/context";
import { Context as SystemContext } from "src/contexts/system/context";
import { MessageType } from "src/renderer/MessageType";
import RestaurantCard from "src/screens/RestaurantRecommendations/RestaurantCard";
import { getRestaurants } from "src/screens/RestaurantRecommendations/viewModel";
import Template from "src/templates";
import { getDestinationName } from "src/utilities";
import "./style.css";
import { RestaurantInfo } from "./type";

export default function RestaurantRecommendations(): React.ReactElement {
  const chat = useContext(ChatContext);
  const navigation = useContext(NavigationContext);
  const pan = useContext(PANContext);
  const preferencesContext = useContext(PreferencesContext);
  const restaurant = useContext(RestaurantContext);
  const system = useContext(SystemContext);
  const [styling, setStyling] = useState(
    "absolute bg-[white] h-[100%] slide-in top-0 w-full z-[7000]"
  );
  const template = Template(system.language);
  const emptyRestaurants: RestaurantInfo[] = [];
  const [restaurants, setRestaurants] = useState(emptyRestaurants);

  function renderRestaurants(restaurants: RestaurantInfo[]): React.ReactElement[] {
    return restaurants.map(function (info, i) {
      return (
        <RestaurantCard
          {...info}
          key={i}
          onNavigate={function () {
            restaurant.update({ ...restaurant, id: info.id.toString() });
            navigation.update({ ...navigation, flow: Flow.RESTAURANT_DETAILS });
          }}
        />
      );
    });
  }

  useEffect(
    function () {
      getRestaurants(
        pan.number,
        preferencesContext.destination?.location.id,
        system.language,
        template
      )
        .then((response) => setRestaurants(response))
        .catch(function () {
          // TODO: Update the UI to show the failure of getting any restaurant recommendations
        });
    },
    [preferencesContext.destination?.location, system.language, pan.number, template]
  );

  const destinationHeaderStyling =
    system.language === "en"
      ? "text-black font-bold text-[18px] leading-[23px]"
      : "text-black font-semibold text-[18px] leading-[23px]";
  const destinationStyling =
    system.language === "en"
      ? "locationLabel max-w-full max-h-[18px] font-bold text-[14px] leading-[18px] tracking-[0.25px] text-primary"
      : "locationLabel max-w-full max-h-[18px] font-semibold text-[14px] leading-[18px] tracking-[0.25px] text-primary";
  const choosePreferenceStyling =
    system.language === "en"
      ? "text-primary font-medium text-[14px] leading-6"
      : "text-primary font-bold text-[14px] leading-6";

  return (
    <div className={styling}>
      <Header
        backHandler={() => {
          setStyling("absolute bg-[white] h-[100%] slide-out top-0 w-full z-[7000]");
          setTimeout(() => navigation.update({ ...navigation, flow: Flow.CHAT }), 500);
        }}
        title={template.restaurantRecommendations.header}
      />
      <div className="flex flex-col overflow-y-scroll gap-6 pb-[13px] h-[calc(100%-4rem)] pt-[2rem] recommendation">
        <div className="flex flex-col items-start px-6 gap-3">
          <label className={destinationHeaderStyling}>
            {template.restaurantRecommendations.diningDestination}
          </label>
          <button
            className="flex py-1 gap-1"
            onClick={() =>
              navigation.update({ ...navigation, flow: Flow.REQUEST_DESTINATION_RECOMMENDATIONS })
            }>
            <label className={destinationStyling}>
              {getDestinationName(template, "", preferencesContext.destination)}
            </label>
            <img className="w-[18px] pt-[1px]" src={icons.caretDown}></img>
          </button>
        </div>
        <button
          className="flex items-center gap-1 px-6"
          onClick={() => navigation.update({ ...navigation, flow: Flow.PREFERENCES })}>
          <label className={choosePreferenceStyling}>
            {template.restaurantRecommendations.chooseYourPreference}
          </label>
          <img className="w-[18px] h-[18px] pt-[1px]" src={icons.caretDown}></img>
        </button>
        <div>{renderRestaurants(restaurants)}</div>
        <div className="flex justify-center py-6 px-6">
          <ConfirmButton
            sizing="large"
            selected={true}
            handler={function () {
              if (chat.current === MessageType.DESTINATION_SELECTION + 0.5) {
                setTimeout(() => chat.update({ ...chat, current: chat.current + 0.5 }), 500);
              }
              navigation.update({
                ...navigation,
                flow: Flow.CHAT,
              });
            }}>
            {template.actions.nextStep}
          </ConfirmButton>
        </div>
      </div>
    </div>
  );
}
