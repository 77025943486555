import React, { useContext, useState } from "react";

import ConfirmButton from "src/components/Carousal/OptionButton";
import ChatHeader from "src/components/ChatHeader";
import GiftCardList from "src/components/GiftCardList";
import { Rewards } from "src/components/GiftCardList/constants";
import { Context as EnrollmentContext } from "src/contexts/enrollment/context";
import { Context as NavigationContext, Flow } from "src/contexts/navigation/context";
import { Context as SystemContext } from "src/contexts/system/context";
import Template from "src/templates";
import "./style.css";

export default function EnrollmentSelectReward(): React.ReactElement {
  const navigation = useContext(NavigationContext);
  const enrollment = useContext(EnrollmentContext);
  const system = useContext(SystemContext);
  const template = Template(system.language);
  const [selectedReward, setSelectedReward] = useState<Rewards>();
  const [styling, setStyling] = useState(
    "campaign absolute bg-[white] h-[100%] slide-in top-0 w-full z-[8100]"
  );
  function backHandler() {
    if (selectedReward) {
      enrollment.update({ ...enrollment, selectedReward: undefined });
    }
    setStyling("campaign absolute bg-[white] h-[100%] slide-out top-0 w-full z-[8100]");
    setTimeout(() => navigation.update({ ...navigation, flow: Flow.VISA_XB_CAMPAIGN }), 500);
  }
  return (
    <div className={styling}>
      <ChatHeader title={template.enrollment.enrollment} backHandler={backHandler} />
      <div className="flex flex-col h-[calc(100%-4rem)] bg-white justify-between px-[1.5rem] pt-[2rem]">
        <div className="flex flex-col">
          <div className="text-[1.375rem] font-semibold">{template.enrollment.selectReward}</div>
          <div className="text-[0.875rem] pt-[1.5rem] pb-[0.5rem]">
            {template.enrollment.chooseReward}
          </div>
          <div className="text-[0.875rem] py-[1.25rem] font-bold text-primary leading-5">
            <label
              onClick={() => {
                navigation.update({ ...navigation, flow: Flow.VISA_XB_CAMPAIGN });
              }}>
              {template.enrollment.visaXBCampaign}
            </label>
          </div>
          <GiftCardList setSelectedReward={setSelectedReward} />
        </div>
        <div className="flex justify-center py-[1.5rem]">
          <ConfirmButton
            disabled={selectedReward === undefined}
            selected
            sizing="large"
            handler={() => {
              enrollment.update({ ...enrollment, selectedReward: selectedReward });
              navigation.update({ ...navigation, flow: Flow.ENROLLMENT_SELECT_CARD });
            }}>
            {template.actions.next}
          </ConfirmButton>
        </div>
      </div>
    </div>
  );
}
