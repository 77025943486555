import { createContext } from "react";

import { BankCard } from "src/components/AssistantBankCard/constants";
import { Rewards } from "src/components/GiftCardList/constants";

export interface ContextType {
  selectedCard?: BankCard[];
  selectedReward?: Rewards;
  update: (context: ContextType) => void;
}

export const defaultContext: ContextType = {
  selectedCard: undefined,
  selectedReward: undefined,
  update: () => false,
};

export const Context = createContext<ContextType>(defaultContext);
