import React, { useContext } from "react";

import "./App.css";
import { Context as NavigationContext, Flow } from "./contexts/navigation/context";
import CampaignDetails from "./screens/CampaignDetails";
import Chat from "./screens/Chat";
import DestinationRequest from "./screens/DestinationRequest";
import EnrollmentSelectCard from "./screens/EnrollmentSelectCard";
import EnrollmentSelectReward from "./screens/EnrollmentSelectReward";
import EnrollmentSuccess from "./screens/EnrollmentSuccess";
import LandingPage from "./screens/LandingPage";
import PANRequest from "./screens/PANRequest";
import PANSelect from "./screens/PANSelect";
import Preferences from "./screens/Preferences";
import NavigationModal from "./screens/RestaurantNavigation";
import RestaurantRecommendations from "./screens/RestaurantRecommendations";
import RestaurantDetails from "./screens/RestaurantDetails";

function renderPage(flow: Flow) {
  switch (flow) {
    case Flow.MAIN:
      return <LandingPage />;
    case Flow.CHAT:
      return <Chat />;
    case Flow.REQUEST_DESTINATION:
      return (
        <>
          <Chat />
          <DestinationRequest />
        </>
      );
    case Flow.REQUEST_PAN:
      return (
        <>
          <Chat />
          <PANRequest />
        </>
      );
    case Flow.SELECT_PAN:
      return (
        <>
          <Chat />
          <PANSelect />
        </>
      );
    case Flow.PREFERENCES:
      return (
        <>
          <Chat />
          <RestaurantRecommendations />
          <Preferences />
        </>
      );
    case Flow.RECOMMENDATIONS:
      return (
        <>
          <Chat />
          <RestaurantRecommendations />
        </>
      );
    case Flow.REQUEST_DESTINATION_RECOMMENDATIONS:
      return (
        <>
          <Chat />
          <RestaurantRecommendations />
          <DestinationRequest />
        </>
      );
    case Flow.RESTAURANT_NAVIGATION:
      return (
        <>
          <Chat />
          <RestaurantRecommendations />
          <NavigationModal />
        </>
      );
    case Flow.RESTAURANT_DETAILS:
      return (
        <>
          <Chat />
          <RestaurantDetails />
        </>
      );
    case Flow.RESTAURANT_DETAILS_NAVIGATION:
      return (
        <>
          <Chat />
          <RestaurantDetails />
          <NavigationModal back={Flow.RESTAURANT_DETAILS} />
        </>
      );
    case Flow.ENROLLMENT_SELECT_REWARD:
      return (
        <>
          <Chat />
          <RestaurantRecommendations />
          <CampaignDetails />
          <EnrollmentSelectReward />
        </>
      );
    case Flow.ENROLLMENT_SELECT_CARD:
      return (
        <>
          <Chat />
          <RestaurantRecommendations />
          <CampaignDetails />
          <EnrollmentSelectReward />
          <EnrollmentSelectCard />
        </>
      );
    case Flow.ENROLLMENT_SUCCESS:
      return (
        <>
          <Chat />
          <RestaurantRecommendations />
          <EnrollmentSelectCard />
          <EnrollmentSuccess />
        </>
      );
    case Flow.VISA_XB_CAMPAIGN:
      return (
        <>
          <Chat />
          <RestaurantRecommendations />
          <CampaignDetails />
        </>
      );
    case Flow.LOADING:
      return <div></div>;
  }
}

function App(): React.ReactElement {
  const navigation = useContext(NavigationContext);
  return <div className="app-screen">{renderPage(navigation.flow)}</div>;
}

export default App;
