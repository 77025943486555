import React, { useContext, useEffect } from "react";

import { Context as ChatContext } from "src/contexts/chat/context";
import { Context as NavigationContext } from "src/contexts/navigation/context";
import { Context as PANContext } from "src/contexts/PAN/context";
import { MessageType } from "src/renderer/MessageType";

import AssistantListSelectionChatBubble from "../AssistantListSelectionChatBubble";
import { Props as OptionProps } from "../AssistantListSelectionChatBubble/Option";
import UserConfirmedPANChatBubble from "../UserConfirmedPANChatBubble";
import { getFlowByIndex } from "./viewModel";

export interface Props {
  date?: Date;
  message: string;
  response: string;
  options: OptionProps[];
}

export default function CardSelection({
  date,
  message,
  response,
  options,
}: Props): React.ReactElement {
  const navigation = useContext(NavigationContext);
  const pan = useContext(PANContext);
  const chat = useContext(ChatContext);

  useEffect(() => {
    if (chat.current === MessageType.DESTINATION_SELECTION - 0.5) {
      setTimeout(() => chat.update({ ...chat, current: chat.current + 0.5 }), 2000);
    }
  }, [chat]);

  const opts = options.map((option, i) => ({
    ...option,
    disabled: !!pan.number,
    onPress: () => navigation.update({ ...navigation, flow: getFlowByIndex(i) }),
  }));

  return (
    <div className="h-[50%] w-[100%] flex flex-col flex-grow justify-between">
      <AssistantListSelectionChatBubble date={date} message={message} options={opts} />
      {pan.number && (
        <UserConfirmedPANChatBubble date={pan.date} pan={pan.number} template={response} />
      )}
    </div>
  );
}
