import { Language } from "src/configurations";
import Template, { TemplateDetails } from "src/templates";

export type MapLink = {
  map: string;
  link: string;
};

export function getMaps(language: Language): MapLink[] {
  const template = Template(language);
  return [
    {
      map: template.navigationApp.googleMap,
      //API Doc https://developers.google.com/maps/documentation/urls/get-started
      link: "http://www.google.com/maps/@",
    },
    {
      map: template.navigationApp.appleMap,
      //API Doc https://developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/MapLinks/MapLinks.html
      link: "http://maps.apple.com/?z=15&t=s&ll=",
    },
    {
      map: template.navigationApp.baiduMap,
      //Web API Doc https://lbsyun.baidu.com/index.php?title=uri/api/web
      link: "http://api.map.baidu.com/marker?output=html&src=vxc-poc&location=",
    },
    {
      map: template.navigationApp.tencentMap,
      //Web API Doc https://lbs.qq.com/webApi/uriV1/uriGuide/uriWebMarker
      link: "https://apis.map.qq.com/uri/v1/marker?referer=vxc-poc&marker=coord:",
    },
  ];
}

export function getDefaultPhoneNumber(city: string, template: TemplateDetails): string {
  if (city === template.cities.losAngeles) {
    return "（323）469-4897";
  } else {
    return "（65）1234-4321";
  }
}
