import React, { useContext, useState, useEffect } from "react";
import moment from "moment-timezone";

import { icons } from "src/assets";
import AbstractCard from "src/components/AbstractCard";
import ChatHeader from "src/components/ChatHeader";
import ListTile from "src/components/ListTile";
import Modal from "src/components/Modal";
import { Context as NavigationContext, Flow } from "src/contexts/navigation/context";
import { Context as PreferencesContext } from "src/contexts/preferences/context";
import { Context as RestaurantContext, TYPES } from "src/contexts/restaurant/context";
import { Context as SystemContext } from "src/contexts/system/context";
import Template from "src/templates";
import { checkOpen, getLocalTime, getOpeningHours } from "src/utilities";
import { getRestaurantDetails } from "./viewModel";
import { listicle, altTexts, emptyDetails } from "./constants";
import style from "./style.module.css";

export default function RestaurantDetails(): React.ReactElement {
  const navigation = useContext(NavigationContext);
  const system = useContext(SystemContext);
  const restaurant = useContext(RestaurantContext);
  const template = Template(system.language);
  const preferences = useContext(PreferencesContext);
  const [details, setDetails] = useState(emptyDetails);
  const [timetable, setTimetable] = useState(false);

  useEffect(() => {
    getRestaurantDetails(restaurant.id, system.language, system.shouldMockAPI)
      .then((details) => setDetails(details))
      .catch(() => setDetails(emptyDetails));
  }, [restaurant.id, system.language, system.shouldMockAPI]);

  const clickHandler = (type: TYPES) => {
    restaurant.update({
      ...restaurant,
      type: type,
      url: details.website,
      location: {
        latitude: details.location.lat,
        longitude: details.location.lng,
        address: details.address,
      },
      phone: details.phone,
    });
    navigation.update({
      ...navigation,
      flow: Flow.RESTAURANT_DETAILS_NAVIGATION,
    });
  };
  const localTime = getLocalTime(preferences.destination?.city, template);
  const dayOfWeek = details.location.timezone ? moment().tz(details.location.timezone).day() : 0;
  const openingHoursToday = getOpeningHours(dayOfWeek, details.opening_hours);
  const isOpen = checkOpen(localTime, openingHoursToday);
  return (
    <>
      <div className={style.page} lang={system.language}>
        <ChatHeader
          backHandler={() => navigation.update({ ...navigation, flow: Flow.RECOMMENDATIONS })}
          title={template.restaurantDetails.header}
        />
        <div className={style.hero}>
          <img src={details.imageURL} alt={details.name} />
        </div>
        <div className={style.details}>
          <div>
            <h2>{details.name}</h2>
            <div>
              <div>
                <img src={icons.clock} alt={altTexts.time} />
              </div>
              <h4>{`${
                isOpen
                  ? template.restaurantDetails.details.open
                  : template.restaurantChatCard.isOpen.closed
              } ${template.restaurantDetails.details.localTime}${localTime}`}</h4>
            </div>
            <button type="button" onClick={() => setTimetable(true)}>
              <span>{`${template.restaurantDetails.details.todayOpen} ${openingHoursToday}`}</span>
              <div>
                <img src={icons.caretDown} alt={altTexts.dropdown} />
              </div>
            </button>
          </div>
          <AbstractCard
            options={details.categories}
            sideCaption={template.restaurantDetails.details.specialty}
            handler={() => setTimetable(false)}>
            {details.description}
          </AbstractCard>
          <div className="flex justify-between">
            <ListTile icon={listicle.icons.bill} title={details.price_level} />
            <button
              className={style.action}
              type="button"
              onClick={() => clickHandler(TYPES.PHONE)}>
              <div>
                <img
                  src={icons.ring}
                  alt={template.restaurantDetails.details.contact}
                  className="w-[18px] h-[18px] pr-1"
                />
              </div>
              <span>{template.restaurantDetails.details.contact}</span>
            </button>
            <button
              className={style.action}
              type="button"
              onClick={() => clickHandler(TYPES.WEBSITE)}>
              <div>
                <img
                  src={icons.phone}
                  alt={template.restaurantDetails.details.website}
                  className="w-[18px] h-[18px] pr-1"
                />
              </div>
              <span>{template.restaurantDetails.details.website}</span>
            </button>
          </div>
          <ListTile
            icon={listicle.icons.address}
            title={template.restaurantDetails.information.address}
            content={details.address}
            borderTop>
            <button className={style.action} type="button" onClick={() => clickHandler(TYPES.MAPS)}>
              <div>
                <img src={icons.location} alt={altTexts.location} />
              </div>
            </button>
          </ListTile>
          {details.vibes.length > 0 && (
            <ListTile
              icon={listicle.icons.atmosphere}
              title={template.restaurantDetails.information.atmosphere}
              content={details.vibes}
              borderTop
            />
          )}
          {details.services.length > 0 && (
            <ListTile
              icon={listicle.icons.service}
              title={template.restaurantDetails.information.service}
              content={details.services}
              borderTop
            />
          )}
          {details.payments.length > 0 && (
            <ListTile
              icon={listicle.icons.payment}
              title={template.restaurantDetails.information.payment}
              content={details.payments}
              borderTop
            />
          )}
          {details.reservations.length > 0 && (
            <ListTile
              icon={listicle.icons.reservation}
              title={template.restaurantDetails.information.reservation}
              content={details.reservations}
              borderTop></ListTile>
          )}
        </div>
      </div>
      {timetable ? (
        <Modal
          closeHandler={() => setTimetable(false)}
          title={template.restaurantDetails.details.schedule}>
          <div className={style.modal}>
            {details.opening_hours.map((day, index) => (
              <div key={`slot-${index}-`}>{`${template.weekdays[index]} : ${day}`}</div>
            ))}
          </div>
        </Modal>
      ) : null}
    </>
  );
}
