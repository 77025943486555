import React, { useContext, useState } from "react";

import Modal from "src/components/Modal";
import { Context as ChatContext } from "src/contexts/chat/context";
import { Context as NavigationContext } from "src/contexts/navigation/context";
import { Context as PreferencesContext, Location } from "src/contexts/preferences/context";
import { Context as SystemContext } from "src/contexts/system/context";
import Template from "src/templates";
import DestinationListCard from "./DestinationListCard";
import { getCities, getLocationList, onBack, onSubmit } from "./viewModel";

export default function DestinationRequest(): React.ReactElement {
  const navigation = useContext(NavigationContext);
  const preferences = useContext(PreferencesContext);
  const system = useContext(SystemContext);
  const chat = useContext(ChatContext);
  const locationList = getLocationList(system.language);
  const template = Template(system.language);
  const [city, setCity] = useState(getCities(template)[0]);
  const [location, setLocation] = useState<Location>();

  return (
    <Modal
      closeHandler={() => onBack(navigation)}
      submitButton={{
        disable: !location,
        onPress: () => onSubmit(chat, preferences, city, location),
        title: template.actions.confirm,
      }}
      title={template.requestDestination.header}>
      <DestinationListCard
        city={city}
        label={template.requestDestination.dropdown.title}
        location={location}
        locationList={locationList}
        setCity={setCity}
        setLocation={setLocation}
      />
    </Modal>
  );
}
