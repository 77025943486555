import React, { useContext } from "react";

import { icons } from "src/assets";
import { Context as SystemContext } from "src/contexts/system/context";
import Template from "src/templates";
import "./style.css";

interface AcceptanceMerchantsProps {
  details: {
    food: {
      description: string;
      title: string;
    };
    shopping: {
      description: string;
      title: string;
    };
    subscription: {
      description: string;
      title: string;
    };
    transportation: {
      description: string;
      title: string;
    };
  };
  title: string;
}

interface AvailableCardProps {
  description: string;
  title: string;
}

interface CampaignPeriodProps {
  description: string;
  title: string;
}

export interface Props {
  description: string;
  title: string;
}

function AcceptanceMerchants({ details, title }: AcceptanceMerchantsProps): React.ReactElement {
  return (
    <div className="flex pt-[9px] space-x-[25px] w-full">
      <img className="h-[40px] w-[40px]" src={icons.acceptanceMerchants}></img>
      <div className="flex flex-col space-y-[5px] w-full">
        <label className="font-[600] text-[16px]">{title}</label>
        <div className="inline space-y-[5px] w-full">
          <label className="font-[600] text-[16px]">{details.food.title}</label>&nbsp;:&nbsp;
          <label className="font-[300] text-[14px] text-charcoal">{details.food.description}</label>
        </div>
        <div className="inline space-y-[5px] w-full">
          <label className="font-[600] text-[16px]">{details.shopping.title}</label>&nbsp;:&nbsp;
          <label className="font-[300] text-[14px] text-charcoal">
            {details.shopping.description}
          </label>
        </div>
        <div className="inline space-y-[5px] w-full">
          <label className="font-[600] text-[16px]">{details.subscription.title}</label>
          &nbsp;:&nbsp;
          <label className="font-[300] text-[14px] text-charcoal">
            {details.subscription.description}
          </label>
        </div>
        <div className="inline space-y-[5px] w-full">
          <label className="font-[600] text-[16px]">{details.transportation.title}</label>
          &nbsp;:&nbsp;
          <label className="font-[300] text-[14px] text-charcoal">
            {details.transportation.description}
          </label>
        </div>
      </div>
    </div>
  );
}

function AvailableCard({ description, title }: AvailableCardProps): React.ReactElement {
  return (
    <div className="flex space-x-[25px] w-full">
      <img className="h-[40px] w-[40px]" src={icons.cardGeneric}></img>
      <div className="flex flex-col space-y-[5px] w-full">
        <label className="font-[600] text-[16px]">{title}</label>
        <label className="font-[400] text-[14px] text-charcoal">{description}</label>
      </div>
    </div>
  );
}

function CampaignPeriod({ description, title }: CampaignPeriodProps): React.ReactElement {
  return (
    <div className="flex pt-[9px] space-x-[25px] w-full">
      <img className="h-[40px] w-[40px]" src={icons.calendar}></img>
      <div className="flex flex-col space-y-[5px] w-full">
        <label className="font-[600] text-[16px]">{title}</label>
        <label className="font-[400] text-[14px] text-charcoal">{description}</label>
      </div>
    </div>
  );
}

export default function Introduction({ description, title }: Props): React.ReactElement {
  const system = useContext(SystemContext);
  const template = Template(system.language);

  return (
    <div className="flex flex-col mt-[16px] space-y-[16px] w-full">
      <div className="align-center flex justify-center w-full">
        <label className="font-[600] text-[18px]">{title}</label>
      </div>
      <div className="w-full">
        <label className="font-[400] text-[14px]">{description}</label>
      </div>
      <AvailableCard {...template.campaign.details.availableCard} />
      <CampaignPeriod {...template.campaign.details.campaignTime} />
      <AcceptanceMerchants {...template.campaign.details.acceptanceMerchants} />
    </div>
  );
}
