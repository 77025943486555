import React, { useContext } from "react";
import moment from "moment";

import { icons, images } from "src/assets";
import TagButton from "src/components/AbstractCard/TagButton";
import {
  Context as NavigationContext,
  ContextType as NavigationContextType,
  Flow,
} from "src/contexts/navigation/context";
import { Context as PreferencesContext } from "src/contexts/preferences/context";
import { Context as SystemContext } from "src/contexts/system/context";
import Template from "src/templates";
import { checkOpen, getLocalTime, getOpeningHours } from "src/utilities";
import { RestaurantInfo } from "../type";
import "./style.css";

export interface Props extends RestaurantInfo {
  onNavigate: () => void;
}

function renderCampaign(navigation: NavigationContextType, campaign?: string): React.ReactElement {
  if (campaign) {
    return (
      <div
        className="flex justify-between items-center gap-6 pt-2 pb-4"
        onClick={() => navigation.update({ ...navigation, flow: Flow.VISA_XB_CAMPAIGN })}>
        <div className="flex item-start rounded-sm">
          <img className="w-10 h-[25px] pr-[10px]" src={images.visa.card}></img>
          <label className="font-semibold text-primary text-sm leading-[18px] tracking-[0.25px] pt-[3px]">
            {campaign}
          </label>
        </div>
        <img className="w-[18px]" src={icons.caretRight} />
      </div>
    );
  } else {
    return <div className="pb-4"></div>;
  }
}

function renderCategories(categories: string[], onNavigate: () => void): React.ReactElement {
  if (categories.length > 0) {
    return (
      <div className="flex flex-wrap items-start" onClick={onNavigate}>
        {categories.map(function (category, i) {
          return <TagButton key={i}>{category}</TagButton>;
        })}
      </div>
    );
  } else {
    return <></>;
  }
}

export default function RestaurantCard({
  campaign,
  categories,
  description,
  onNavigate,
  opening_hours,
  price,
  title,
}: Props): React.ReactElement {
  const system = useContext(SystemContext);
  const template = Template(system.language);
  const navigation = useContext(NavigationContext);
  const preferences = useContext(PreferencesContext);
  const localTime = getLocalTime(preferences.destination?.city, template);
  const dayOfWeek =
    preferences.destination?.city === template.cities.losAngeles
      ? moment().tz("America/Los_Angeles").day()
      : moment().tz("Asia/Singapore").day();
  const isOpen = checkOpen(localTime, getOpeningHours(dayOfWeek, opening_hours));
  return (
    <div className="bg-white flex flex-col px-4 pt-4 gap-3 rounded-xl shadow-[0px_4px_20px_rgba(0,0,0,0.1)] mx-6 mb-3">
      <div className="flex flex-col items-start gap-1" onClick={onNavigate}>
        <label className="font-semibold text-neroBlack text-lg leading-[23px] pb-2">{title}</label>
        {description && (
          <>
            <img src={icons.apostrophe} className="w-[18px] rotate-180"></img>
            <label className="font-semibold text-base leading-[18px] tracking-[0.25px] text-[rgba(0,0,0,0.5)] pl-6 -mt-[12px]">
              {description}
            </label>
          </>
        )}
      </div>
      {renderCategories(categories, onNavigate)}
      <div
        className="flex items-start border-t-[0.5px] border-t-[rgba(0,0,0,0.1)] border-solid gap-12 pt-3"
        onClick={onNavigate}>
        <div className="flex items-center gap-1">
          <img className="w-[18px] h-[18px]" src={icons.clock}></img>
          <label className="text-black font-normal text-xs leading-4">
            {isOpen
              ? template.restaurantChatCard.isOpen.open
              : template.restaurantChatCard.isOpen.closed}
          </label>
        </div>
        <div className="flex items-center gap-1">
          <img className="w-[18px] h-[18px]" src={icons.bill} />
          <label className="text-black font-normal text-xs leading-4">{price}</label>
        </div>
      </div>
      {renderCampaign(navigation, campaign)}
    </div>
  );
}
