import React, { useContext, useState } from "react";

import { images } from "src/assets";
import BankCardInput from "src/components/BankCardInput";
import ConfirmButton from "src/components/Carousal/OptionButton";
import Header from "src/components/Header";
import { Context as ChatContext } from "src/contexts/chat/context";
import { Context as NavigationContext, Flow } from "src/contexts/navigation/context";
import { Context as PANContext } from "src/contexts/PAN/context";
import { Context as SystemContext } from "src/contexts/system/context";
import Template from "src/templates";
import { PANFormat } from "src/utilities/constants";

import "./style.css";
import { TNC } from "./TNC";
import { getTNC } from "./viewModels";

export default function PANRequest(): React.ReactElement {
  const chat = useContext(ChatContext);
  const navigation = useContext(NavigationContext);
  const pan = useContext(PANContext);
  const system = useContext(SystemContext);
  const [number, setNumber] = useState("");
  const [select, setSelect] = useState(false);
  const [styling, setStyling] = useState(
    "absolute bg-[white] h-[100%] slide-in top-0 w-full z-[9000]"
  );
  const template = Template(system.language);

  function backHandler() {
    setStyling("absolute bg-[white] h-[100%] slide-out top-0 w-full z-[9000]");
    setTimeout(() => navigation.update({ ...navigation, flow: Flow.CHAT }), 500);
  }

  return (
    <div className={styling}>
      <Header backHandler={backHandler} title={template.requestPAN.header} />
      <div className="pan-request-body pan-request-content overflow-y-scroll h-[calc(100%-8rem)]">
        <div className="flex flex-col">
          <BankCardInput
            card={{ image: images.visa.defaultCard, number }}
            header={template.requestPAN.title}
            input={{
              label: template.requestPAN.inputLabel,
              onChange: (number) => setNumber(number),
            }}
          />
          <div className="flex flex-col">
            <label className="font-semibold text-[14px] pt-[1rem]">
              {template.requestPAN.disclaimer}
            </label>
          </div>
          <div className="flex flex-row pt-[1.5rem]">
            <div className="p-[0.5rem]">
              <input
                className="checkbox"
                type="checkbox"
                checked={select}
                onChange={() => setSelect(!select)}
              />
            </div>
            <ul className="text-[0.75rem] p-[0.5rem] text-[#173BE8] list-none">
              {getTNC(system.language).map((tnc, i) => (
                <TNC key={i} {...tnc} />
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="mb-[16px] mt-[8px] px-[24px] h-[4rem] flex justify-center">
        <ConfirmButton
          disabled={number.length !== PANFormat.length.value || !select}
          selected
          sizing="large"
          handler={() => {
            pan.update({ ...pan, date: new Date(), number });
            setTimeout(() => chat.update({ ...chat, current: chat.current + 0.5 }), 500);
            backHandler();
          }}>
          {template.actions.addCard}
        </ConfirmButton>
      </div>
    </div>
  );
}
