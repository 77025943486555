import React from "react";

import "./style.css";

export interface Props {
  children: string;
  disabled?: boolean;
  sizing?: "small" | "medium" | "large";
  selected: boolean;
  handler: () => void;
  testid?: string;
}

export default function OptionButton({
  children,
  disabled,
  sizing = "small",
  selected,
  handler,
  testid,
}: Props): React.ReactElement {
  return (
    <button
      className={`optionButton ${sizing}
      ${selected ? " optionSelected" : ""}
      ${disabled ? " optionDisabled" : ""}`}
      disabled={disabled}
      onClick={handler}
      data-testid={testid}>
      <p>{children}</p>
    </button>
  );
}
