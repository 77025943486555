import { restaurantDetails } from "src/backend/services/restaurantDetails";
import { Response } from "src/backend/services/restaurantDetails/model";
import { Language } from "src/contexts/system/context";
import { encodingFix, formatOpeningHours, localise } from "src/utilities";
import { Details } from "./type";

function getOpeningHours(response: Response, language: Language) {
  switch (language) {
    case "en":
      return [
        response.open_hours_en.Monday,
        response.open_hours_en.Tuesday,
        response.open_hours_en.Wednesday,
        response.open_hours_en.Thursday,
        response.open_hours_en.Friday,
        response.open_hours_en.Saturday,
        response.open_hours_en.Sunday,
      ];
    case "zh":
      return [
        response.open_hours_cn.星期一,
        response.open_hours_cn.星期二,
        response.open_hours_cn.星期三,
        response.open_hours_cn.星期四,
        response.open_hours_cn.星期五,
        response.open_hours_cn.星期六,
        response.open_hours_cn.星期日,
      ];
  }
}

export async function getRestaurantDetails(
  id: string,
  language: Language,
  shouldMock: boolean
): Promise<Details> {
  const result = await restaurantDetails(id, shouldMock);
  const payments = localise(language, result.payment.values_en, result.payment.values_cn);
  const reservations = localise(
    language,
    result.reservation.values_en,
    result.reservation.values_cn
  );
  const serviceOptions = localise(
    language,
    result.service_options.values_en,
    result.service_options.values_cn
  );
  const vibe = localise(language, result.vibe.values_en, result.vibe.values_cn);
  const details: Details = {
    address: localise(language, result.address_en, result.address_cn),
    categories: localise(
      language,
      result.categories_en
        .slice(1, result.categories_en.length - 1)
        .split(", ")
        .filter((cat) => cat),
      result.categories_en
        .slice(1, result.categories_en.length - 1)
        .split(", ")
        .filter((cat) => cat)
    ),
    description: encodingFix(localise(language, result.description_en, result.categories_cn)),
    imageURL: result.cover_photo.url,
    location: {
      lat: result.location.lat,
      lng: result.location.lng,
      timezone: result.location.timezone,
    },
    name: encodingFix(localise(language, result.name_en, result.name_cn)),
    opening_hours: formatOpeningHours(getOpeningHours(result, language)),
    payments: Object.keys(payments).filter((option) => payments[option]),
    phone: result.phone,
    price_level: result.price_level,
    reservations: Object.keys(reservations).filter((option) => reservations[option]),
    services: Object.keys(serviceOptions).filter((option) => serviceOptions[option]),
    vibes: Object.keys(vibe).filter((option) => vibe[option]),
    website: result.website_url,
  };
  return details;
}
