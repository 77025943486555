import { KeyboardEvent } from "react";

import { PANFormat, regex } from "src/utilities/constants";

function getTruncatedPosition(position: number): number {
  for (let i = PANFormat.separator.positions.length - 1; i >= 0; i--) {
    if (position > PANFormat.separator.positions[i]) {
      return position - (i + 1);
    }
  }
  return position;
}

export function acceptNumbersOnly(e: KeyboardEvent<HTMLInputElement>) {
  if (e.key !== "Backspace" && e.key !== "Delete" && e.key.match(regex.nonDigit)) {
    e.preventDefault();
  }
}

/**
 * Mask PAN value, leaving the last segment
 * @param pan PAN value with number ONLY
 * @returns masked PAN with the last segment
 */
export function mask(pan: string): string {
  const trimmedPan = pan.replaceAll(" ", "");
  const size = PANFormat.segment.length;
  const maskLength = (PANFormat.segment.count - 1) * size;
  let maskedPan = trimmedPan.slice(0, maskLength).replaceAll(regex.digit, "•");
  if (trimmedPan.length > maskLength) {
    maskedPan = maskedPan.concat(trimmedPan.slice(maskLength));
  }
  const segments: string[] = [];
  for (let i = 0; i < Math.ceil(maskedPan.length / size); i++) {
    segments.push(maskedPan.slice(i * size, (i + 1) * size));
  }
  return segments.join(PANFormat.separator.text);
}

/**
 * Unmask masked PAN
 * @param input masked PAN
 * @param position last edit cursor position
 * @param value stored PAN value
 * @returns PAN value
 */
export function unmask(input: string, position: number, value: string): string {
  const p = getTruncatedPosition(position);
  const trimmedInput = input.replaceAll(" ", "");
  if (value.length > trimmedInput.length) {
    const firstPart = value.slice(0, p);
    const lastPart = value.slice(p + 1, value.length);
    const result = firstPart + lastPart;
    return result;
  } else {
    const firstPart = value.slice(0, p);
    const lastPart = value.slice(p + 1, value.length);
    const result = firstPart + input[position - 1] + lastPart;
    return result;
  }
}
