import { createContext } from "react";

export interface ContextType {
  date?: Date;
  number: string;
  update: (context: ContextType) => void;
}

export const defaultContext: ContextType = {
  date: undefined,
  number: "",
  update: () => false,
};

export const Context = createContext<ContextType>(defaultContext);
