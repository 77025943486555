import { createContext } from "react";
import { City, Location as LocationId } from "src/templates";

export interface Location {
  en: string;
  id: LocationId;
  zh: string; // empty string if no Mandarin name
}

export interface Destination {
  city: City;
  location: Location;
}

export interface ContextType {
  destination?: Destination;
  distances: { name: string; value: string }[];
  filters: { name: string; value: string[] }[];
  update: (context: ContextType) => void;
}

export const defaultContext: ContextType = {
  update: () => false,
  distances: [],
  filters: [],
  destination: undefined,
};

export const Context = createContext<ContextType>(defaultContext);
