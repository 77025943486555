import { createContext } from "react";

interface Response {
  date: Date;
  feedback: string;
}

export interface ContextType {
  current: number;
  greeting?: Response;
  update: (context: ContextType) => void;
}

export const defaultContext: ContextType = {
  current: 1,
  greeting: undefined,
  update: () => false,
};

export const Context = createContext<ContextType>(defaultContext);
