import { createContext } from "react";

export type Language = "en" | "zh";

export interface ContextType {
  language: Language;
  shouldMockAPI: boolean;
  update: (context: ContextType) => void;
}

export const defaultContext: ContextType = {
  language: "en",
  shouldMockAPI: false,
  update: () => false,
};

export const Context = createContext<ContextType>(defaultContext);
