import { Location } from "src/templates";
import { Coordinate } from "./Coordinate";

export const coordinates: { [key in Location]: Coordinate } = {
  beverlyHills: { latitude: 34.0736, longitude: -118.4004 },
  chinatown: { latitude: 34.1381, longitude: -118.3534 },
  hollywood: { latitude: 34.0928, longitude: -118.3287 },
  merlion: { latitude: 1.2583, longitude: 103.8205 },
  seaAquarium: { latitude: 1.254, longitude: 103.8238 },
  universalStudios: { latitude: 34.0623, longitude: -118.2383 },
  universalStudiosSingapore: { latitude: 1.2782, longitude: 103.8487 },
  visaRobinsonRoad: { latitude: 1.2868, longitude: 103.8545 },
};
