export const testIDs = {
  header: {
    id: "chat-header",
  },
  button: {
    cross: {
      id: "cross",
    },
    back: {
      id: "back",
    },
  },
  icon: {
    back: { id: "arrow-left" },
    cross: { id: "cross" },
  },
};

export const altTexts = {
  back: "Back",
  cross: "Cross",
};
