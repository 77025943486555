import axiosInstance from "src/backend";
import { Route } from "src/backend/constants";
import { Criteria, Request, Response } from "./model";

export async function restaurantRecommendation(criteria: Criteria): Promise<Response> {
  const data: Request = {
    attributeMap: {
      ...criteria,
      needRefreshData: "true",
      searchType: "Restaurant",
    },
  };
  return await axiosInstance.post(Route.RESTAURANT_RECOMMENDATION, data);
}
