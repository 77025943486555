import { Language } from "src/configurations";
import Template from "src/templates";
import { TNCProps } from "./TNC";
import tnc from "src/assets/docs/tnc.pdf";

export function getTNC(language: Language): TNCProps[] {
  const template = Template(language);
  return [
    {
      description: template.requestPAN.terms5,
      link: tnc,
    },
  ];
}
