import React from "react";

import { icons } from "src/assets";
import { altTexts } from "src/components/ChatHeader/constants";
import "./style.css";

export interface Props {
  title: string;
  onClose?: () => void;
  backHandler?: () => void;
}

export default function Header({ backHandler, onClose, title }: Props): React.ReactElement {
  const headerFormat =
    title.length > 20
      ? "font-semibold text-lg leading-[23px] text-black mx-auto"
      : "font-semibold text-[22px] leading-[29px] text-black mx-auto";
  return (
    <div className="flex items-center px-4 py-1 bg-white border-b-[rgba(0,0,0,0.1)] border-b border-solid shadow-[0px_1px_3px_rgba(0,0,0,0.05)] h-[60px]">
      {backHandler ? (
        <button type="button" className="left-[1.5rem]" onClick={backHandler}>
          <img alt={altTexts.back} src={icons.arrowLeft} />
        </button>
      ) : null}
      <label className={headerFormat}>{title}</label>
      {onClose ? (
        <button type="button" className="right-[1.5rem]" onClick={onClose}>
          <img src={icons.cross} alt={altTexts.cross} />
        </button>
      ) : null}
    </div>
  );
}
