import React, { useContext, useEffect, useState } from "react";

import { Context as ChatContext } from "src/contexts/chat/context";
import UserChatBubble from "../UserChatBubble";
import AssistantListSelectionChatBubble from "../AssistantListSelectionChatBubble";
import { MessageType } from "../../renderer/MessageType";

export interface Props {
  date?: Date;
  options: string[];
  message: string;
}

export default function CollectFeedback({ date, options, message }: Props): React.ReactElement {
  const chat = useContext(ChatContext);
  const [response, setResponse] = useState<string>();

  useEffect(() => {
    if (chat.current === MessageType.END - 0.5) {
      setTimeout(() => chat.update({ ...chat, current: chat.current + 0.5 }), 500);
    }
  }, [chat]);
  const opts = options.map((option) => ({
    disabled: !!response,
    onPress: () => {
      setResponse(option);
      setTimeout(() => chat.update({ ...chat, current: chat.current + 0.5 }), 500);
    },
    text: option,
  }));
  return (
    <div className="h-[50%] w-[100%] flex flex-col flex-grow justify-between">
      <AssistantListSelectionChatBubble date={date} message={message} options={opts} />
      {response && (
        <UserChatBubble
          date={date ?? new Date()}
          message={response}
          style="font-[600] leading-[20px] text-[16px] text-white"
        />
      )}
    </div>
  );
}
