import React, { ChangeEvent, useContext, useState } from "react";

import { icons } from "src/assets";
import { Location } from "src/contexts/preferences/context";
import { Context as SystemContext } from "src/contexts/system/context";
import Template, { City } from "src/templates";
import "./style.css";
import { LocationList } from "../model";
import { getCities } from "../viewModel";

export enum ActionState {
  NONE,
  SELECT_CITY,
  SELECT_LOCATION,
}

export interface Props {
  city: City;
  location?: Location;
  label: string;
  locationList: LocationList;
  setCity: (city: City) => void;
  setLocation: (location: Location) => void;
}

export default function DestinationListCard({
  city,
  label,
  location,
  locationList,
  setCity,
  setLocation,
}: Props): React.ReactElement {
  const system = useContext(SystemContext);
  const template = Template(system.language);
  const cities = getCities(template);
  const [action, setAction] = useState<ActionState>(ActionState.NONE);
  const [keyword, setKeyword] = useState<string>("");
  const [filteredLocations, setFilteredLocations] = useState<Location[]>(locationList[city]);

  function filterByCity(option: City) {
    if (city !== option) {
      setKeyword("");
      setFilteredLocations(locationList[option]);
      setCity(option);
    }
    setAction(ActionState.NONE);
  }

  function searchByKeyword(event: React.ChangeEvent<HTMLInputElement>) {
    const query = event.target.value;
    if (!query) {
      setFilteredLocations(locationList[city]);
    }
    const filtedList = locationList[city].filter(
      (item) => item.en.toLowerCase().includes(query.toLowerCase()) || item.zh.includes(query)
    );
    setFilteredLocations(filtedList);
  }

  const selectedCity = cities.find((value) => value === city);

  return (
    <>
      <div className="flex-col flex gap-[12px] w-full">
        <div className="dropdownFrame">
          <label className="dropdownLabel font-[600] text-black">{label}</label>
          <div className="relative">
            <button
              className="text-primary dropdownButton font-[600]"
              onClick={() => setAction(ActionState.SELECT_CITY)}>
              {selectedCity ? template.cities[selectedCity] : ""}
              <img src={icons.caretDown}></img>
            </button>
            {action === ActionState.SELECT_CITY ? (
              <div className="dropdowns">
                {cities.map((option) => (
                  <button
                    className={`dropdown ${option === city ? "text-primary" : "text-black"}`}
                    key={`${option}-${Date.now()}`}
                    onClick={() => filterByCity(option)}>
                    {template.cities[option]}
                  </button>
                ))}
              </div>
            ) : null}
          </div>
        </div>
        <div className="relative">
          <input
            className="text-primary locationInput"
            id="search-box"
            onChange={searchByKeyword}
            onInput={(e: ChangeEvent<HTMLInputElement>) => {
              setKeyword(e.target.value);
              setAction(ActionState.SELECT_LOCATION);
            }}
            placeholder={template.requestDestination.popup.placeholder}
            type="text"
            value={keyword}
          />
          {action === ActionState.SELECT_LOCATION ? (
            <div className="text-black suggestions">
              {filteredLocations.map((option) => {
                let text = option.zh ? `${option.zh} ${option.en}` : option.en;
                text = text.includes("办公室") ? `${option.zh}` : text;
                return (
                  <button
                    className="text-black suggestion"
                    key={`${option.en}-${Date.now()}`}
                    onClick={() => {
                      setLocation(option);
                      setKeyword(text);
                      setAction(ActionState.NONE);
                    }}>
                    {text}
                  </button>
                );
              })}
            </div>
          ) : null}
        </div>
        <div className="locations">
          {locationList[city].map((name) => {
            let text = name.zh ? `${name.zh} ${name.en}` : name.en;
            text = text.includes("办公室") ? `${name.zh}` : text;
            return (
              <button
                className={`bg-background location 
                  ${name.en === location?.en ? "text-primary" : "text-black"}
                `}
                key={`${name.en}-${Date.now()}`}
                onClick={() => {
                  setLocation(name);
                  setKeyword(text);
                }}>
                {text}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
}
