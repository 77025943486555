import React, { useContext } from "react";

import { icons } from "src/assets";
import ConfirmButton from "src/components/Carousal/OptionButton";
import ChatHeader from "src/components/ChatHeader";
import { Context as EnrollmentContext } from "src/contexts/enrollment/context";
import { Context as NavigationContext, Flow } from "src/contexts/navigation/context";
import { Context as SystemContext } from "src/contexts/system/context";
import Template from "src/templates";

export default function EnrollmentSuccess(): React.ReactElement {
  const navigation = useContext(NavigationContext);
  const enrollment = useContext(EnrollmentContext);
  const system = useContext(SystemContext);
  const template = Template(system.language);

  function getCardNumbers() {
    let last4Digits = "";
    if (enrollment.selectedCard) {
      enrollment.selectedCard.map((card) => {
        last4Digits = last4Digits.concat("•••• ", card.pan.slice(-4), ", ");
      });
      last4Digits = last4Digits.slice(0, -2);
    }
    return last4Digits;
  }
  function onClose() {
    navigation.update({ ...navigation, flow: Flow.RECOMMENDATIONS });
  }

  return (
    <div className="campaign absolute bg-[white] h-[100%] slide-in top-0 w-full z-[9000]">
      <ChatHeader title={template.enrollment.enrollment} onClose={onClose} />
      <div className="flex flex-col h-[calc(100%-4rem)] justify-between px-[1.5rem] pt-[2rem]">
        <div className="flex flex-col">
          <div className="flex justify-center">
            <img src={icons.success} />
          </div>
          <div className="text-[1.375rem] font-semibold py-[1.5rem] text-center">
            {template.enrollment.success}
          </div>
          <div className="text-[1rem] text-center non-chinese">
            {template.enrollment.congratulation.replace("{number}", getCardNumbers)}
          </div>
        </div>
        <div className="flex justify-center py-[1.5rem]">
          <ConfirmButton disabled={false} selected sizing="large" handler={onClose}>
            {template.actions.done}
          </ConfirmButton>
        </div>
      </div>
    </div>
  );
}
