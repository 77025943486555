import React from "react";

import { icons } from "src/assets";
import { testIDs, altTexts } from "./constants";

import style from "./style.module.css";

export interface Props {
  title: string;
  backHandler?: () => void;
  onClose?: () => void;
}

export default function ChatHeader({ title, backHandler, onClose }: Props): React.ReactElement {
  return (
    <>
      <div className={style.chatHeader} data-testid={testIDs.header.id}>
        {backHandler ? (
          <button
            type="button"
            className="left-[1.5rem]"
            onClick={backHandler}
            data-testid={`${testIDs.button.back.id}-button`}>
            <img
              alt={altTexts.back}
              data-testid={`${testIDs.icon.back.id}-icon`}
              src={icons.arrowLeft}
            />
          </button>
        ) : null}
        <div>
          <h1>{title}</h1>
        </div>
        {onClose ? (
          <button
            type="button"
            className="right-[1.5rem]"
            onClick={onClose}
            data-testid={`${testIDs.button.cross.id}-button`}>
            <img
              src={icons.cross}
              alt={altTexts.cross}
              data-testid={`${testIDs.icon.cross.id}-icon`}
            />
          </button>
        ) : null}
      </div>
      <div />
    </>
  );
}
