import React, { Dispatch, SetStateAction, useState, useContext } from "react";
import { Rewards } from "./constants";
import GiftCard from "./GiftCard";
import { getGiftCardList } from "./viewModel";
import { Context as SystemContext } from "src/contexts/system/context";
import Template from "src/templates";

export interface Props {
  setSelectedReward: Dispatch<SetStateAction<Rewards | undefined>>;
}

export default function GiftCardList({ setSelectedReward }: Props): React.ReactElement {
  const system = useContext(SystemContext);
  const template = Template(system.language);
  const [giftCard, setGiftCard] = useState<Rewards[]>(getGiftCardList(template));
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const onClickHandler = (index: number) => {
    if (selectedIndex >= 0) {
      giftCard[selectedIndex].checked = false;
    }
    giftCard[index].checked = true;
    setSelectedIndex(index);
    setGiftCard([...giftCard]);
    setSelectedReward(giftCard[index]);
  };

  return (
    <>
      {giftCard.map((card, index) => {
        return <GiftCard card={card} index={index} key={index} onClickHandler={onClickHandler} />;
      })}
    </>
  );
}
