import details from "src/assets/mocks/restaurant_details.json";
import { Request, Response } from "./model";

export function getMock(request: Request): Response {
  return details.find((item) => item.id.toString() === request.uid) as unknown as Response;
}

export function shouldMock(request: Request): boolean {
  return request.shouldMock ?? false;
}
