import React, { useState } from "react";
import { Context, ContextType, defaultContext } from "../enrollment/context";

interface Props {
  children: React.ReactNode;
}

export default function EnrollmentProvider({ children }: Props) {
  const [context, setContext] = useState<ContextType>({
    selectedReward: defaultContext.selectedReward,
    selectedCard: defaultContext.selectedCard,
    update: (context) => setContext(context),
  });

  return <Context.Provider value={context}>{children}</Context.Provider>;
}
