import React, { useContext, useState } from "react";

import { Context as NavigationContext, Flow } from "src/contexts/navigation/context";
import { Context as PreferenceContext } from "src/contexts/preferences/context";
import { Context as SystemContext } from "src/contexts/system/context";
import Template from "src/templates";

import VerticalNav from "src/components/VerticalNav";
import Button from "src/components/Carousal/OptionButton";
import Modal from "src/components/Modal";
import {
  CATEGORIES,
  grouping,
  displayPreferences,
  getPreferences,
  submitSelections,
  Preferences as Choices,
} from "./viewModel";

import style from "./style.module.css";

export default function Preferences(): React.ReactElement | null {
  const navigation = useContext(NavigationContext);
  const preferences = useContext(PreferenceContext);
  const system = useContext(SystemContext);
  const template = Template(system.language);

  const fields: grouping[] = [CATEGORIES.CUISINE, CATEGORIES.DISTANCE, CATEGORIES.FILTER];

  const [current, setCurrent] = useState<Choices>(
    displayPreferences(getPreferences(), preferences)
  );
  const [category, setCategory] = useState<grouping>(fields[0]);
  const [subcategory, setSubcategory] = useState<number>(0);

  const categoryHandler = (field: grouping) => {
    setSubcategory(0);
    setCategory(field);
  };

  const cuisineHandler = (opt: number) => {
    setCurrent((previous) => ({
      ...previous,
      cuisine: previous.cuisine.map((food, key) => ({
        ...food,
        selected: key !== opt ? food.selected : !food.selected,
      })),
    }));
  };

  const dismissHandler = () => navigation.update({ ...navigation, flow: Flow.RECOMMENDATIONS });

  const distanceHandler = (sub: number, opt: number) => {
    setCurrent((previous) => ({
      ...previous,
      distances: previous.distances.map((distance, index) => ({
        ...distance,
        options:
          sub !== index
            ? distance.options
            : distance.options.map((option, key) => ({
                ...option,
                selected: key === opt,
              })),
      })),
    }));
  };

  const filterHandler = (sub: number, opt: number) => {
    setCurrent((previous) => ({
      ...previous,
      filters: previous.filters.map((filter, index) => ({
        ...filter,
        options:
          index !== sub
            ? filter.options
            : filter.options.map((option, key) => ({
                ...option,
                selected: key !== opt ? option.selected : !option.selected,
              })),
      })),
    }));
  };

  const resetHandler = () => {
    setCurrent((previous) => ({
      ...previous,
      cuisine: previous.cuisine.map((food) => ({ ...food, selected: false })),
      distances: previous.distances.map((distance) => ({
        key: distance.key,
        options: distance.options.map((option) => ({ ...option, selected: false })),
      })),
      filters: previous.filters.map((filter) => ({
        key: filter.key,
        options: filter.options.map((option) => ({ ...option, selected: false })),
      })),
    }));
  };

  function renderOptions(cat: string): React.ReactElement {
    switch (cat) {
      case CATEGORIES.FILTER:
        return (
          <VerticalNav
            navs={current.filters.map((item) => item.key)}
            selected={subcategory}
            selector={setSubcategory}>
            <div className={style.buttons}>
              {current.filters[subcategory].options.map((item, index) => (
                <button
                  className={item.selected ? style.selected : style.unselected}
                  onClick={() => {
                    filterHandler(subcategory, index);
                  }}
                  key={`${CATEGORIES.FILTER}-${index}-`}>
                  {item.label}
                </button>
              ))}
            </div>
          </VerticalNav>
        );
      case CATEGORIES.DISTANCE:
        return (
          <VerticalNav
            navs={current.distances.map((item) => item.key)}
            selected={subcategory}
            selector={setSubcategory}>
            <div className={style.buttons}>
              {current.distances[subcategory].options.map((option, index) => (
                <button
                  className={option.selected ? style.selected : style.unselected}
                  onClick={() => distanceHandler(subcategory, index)}
                  key={`${CATEGORIES.DISTANCE}-${index}-`}>
                  {option.label}
                </button>
              ))}
            </div>
          </VerticalNav>
        );
      default:
        return (
          <VerticalNav>
            <div className={style.buttons}>
              {current.cuisine.map((option, index) => (
                <button
                  className={option.selected ? style.selected : style.unselected}
                  onClick={() => cuisineHandler(index)}
                  key={`${CATEGORIES.CUISINE}-${index}-`}>
                  {option.label}
                </button>
              ))}
            </div>
          </VerticalNav>
        );
    }
  }

  return (
    <Modal title={template.preferences.title} closeHandler={dismissHandler}>
      <div className={style.categories}>
        {fields.map((field, index) => (
          <button key={`${field}-${index}`} onClick={() => categoryHandler(field)}>
            <span className={field === category ? `${style.filter} ${style.up}` : style.filter}>
              {template.preferences[field]}
            </span>
            <svg
              className={field === category ? `${style.triangle} ${style.up}` : style.triangle}
              viewBox="-50 -50 300 200">
              <polygon strokeLinejoin="round" points="100,0 0,120 200,120" />
            </svg>
          </button>
        ))}
      </div>
      <div>{renderOptions(category)}</div>
      <Button
        sizing="large"
        selected
        handler={() => {
          preferences.update({ ...preferences, ...submitSelections(current) });
          dismissHandler();
        }}>
        {template.preferences.submission}
      </Button>
      <button className={style.reset} onClick={() => resetHandler()}>
        {template.preferences.reset}
      </button>
    </Modal>
  );
}
