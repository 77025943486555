import React from "react";

import "./style.css";

export interface Props {
  onPress?: () => void;
  text: string;
}

export default function MapOption({ onPress, text }: Props): React.ReactElement {
  return (
    <div className="flex justify-center items-center gap-2 px-6 py-3 rounded-3xl">
      <button
        className="font-semibold leading-[150%] flex items-center text-center tracking-[0.25px] text-primary"
        onClick={onPress}>
        {text}
      </button>
    </div>
  );
}
