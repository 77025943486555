import React from "react";

export interface TNCProps {
  description: string;
  link: string;
}

export function TNC({ description, link }: TNCProps): React.ReactElement {
  return <li onClick={() => window.open(link, "_blank")}>{description}</li>;
}
