export type Language = "en" | "zh";

interface Configuration {
  app: {
    certs: {
      mle: string;
    };
    host?: string;
  };
  aws: {
    cognito: {
      cognitoRegion?: string;
      domain?: string;
      projectRegion?: string;
      redirectURL: {
        signIn?: string;
        signOut?: string;
      };
      userPool: {
        clientId?: string;
        id?: string;
      };
    };
  };
}

const config: Configuration = {
  app: {
    certs: {
      mle: process.env.REACT_APP_MLE ?? "",
    },
    host: process.env.REACT_APP_HOST,
  },
  aws: {
    cognito: {
      cognitoRegion: process.env.REACT_APP_AWS_COGNITO_REGION,
      domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
      projectRegion: process.env.REACT_APP_AWS_PROJECT_REGION,
      redirectURL: {
        signIn: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_IN_URL,
        signOut: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_OUT_URL,
      },
      userPool: {
        clientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID,
        id: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
      },
    },
  },
};

export default config;
