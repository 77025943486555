import sjcl from "sjcl-with-all";
import jsrsasign from "jsrsasign";
import config from "../configurations";

interface JsrsasignPublicKey {
  pubKeyHex: string;
}

export function getPublicKey() {
  const parsedPublicKey = jsrsasign.KEYUTIL.getKey(
    config.app.certs.mle
  ) as unknown as JsrsasignPublicKey;
  const publicKeyHex = parsedPublicKey.pubKeyHex.substring(2); //remove first byte 0x04 to only provide raw points
  const publicKeyBits = sjcl.codec.hex.toBits(publicKeyHex);
  const publicKey = new sjcl.ecc.elGamal.publicKey(sjcl.ecc.curves.c384, publicKeyBits);
  return publicKey;
}

export function encrypt(payload: string): string {
  const encryptedMessage = sjcl.encrypt(getPublicKey(), payload, { ks: 256 });
  return encryptedMessage;
}
