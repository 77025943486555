import React, { useState } from "react";

import { Context, ContextType, defaultContext } from "./context";

interface Props {
  children: React.ReactNode;
}

export default function ChatProvider({ children }: Props) {
  const [context, setContext] = useState<ContextType>({
    current: defaultContext.current,
    greeting: defaultContext.greeting,
    update: (context) => setContext(context),
  });

  return <Context.Provider value={context}>{children}</Context.Provider>;
}
