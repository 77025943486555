import React from "react";

import { icons, images } from "src/assets";
import { testIDs, altTexts, defaults } from "./constants";
import style from "./style.module.css";

export interface Props {
  name: string;
  role: string;
  rating: number;
}

export default function VirtualAssistant({ name, role, rating }: Props): React.ReactElement {
  if (rating < defaults.rating.lower || rating > defaults.rating.upper) {
    return <div />;
  }

  return (
    <div className={style.virtualAssistant}>
      <div>
        <img
          alt={altTexts.hero}
          data-testid={`${testIDs.assistant.id}-logo`}
          src={images.visa.logo}
        />
      </div>
      <div>
        <p data-testid={testIDs.name.id}>{name}</p>
        <p data-testid={testIDs.role.id}>{role}</p>
      </div>
      <div>
        {Array.from(Array(rating), (_, idx) => (
          <div key={`va-ic${idx}`}>
            <img
              alt={`${altTexts.icon} ${idx + 1}`}
              data-testid={`${testIDs.icon.id}-${idx}-icon`}
              src={icons.starFill}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
