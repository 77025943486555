import React, { useContext } from "react";

import { icons } from "src/assets";
import { Context as SystemContext } from "src/contexts/system/context";
import Template from "src/templates";
import "./style.css";

interface StepProps {
  icon: string;
  name: string;
}

export interface Props {
  description: string[];
  steps: {
    addCard: string;
    enroll: string;
    pay: string;
  };
}

function Step({ icon, name }: StepProps): React.ReactElement {
  return (
    <div className="flex flex-col items-center space-y-[4px] w-fit">
      <img className="h-[56px] w-[56px]" src={icon} />
      <div className="flex flex-col items-center justify-center">
        {name.split("\n").map((text, i) => (
          <label className="font-400] text-[12px]" key={i}>
            {text}
          </label>
        ))}
      </div>
    </div>
  );
}

export default function HowToParticipate({ description, steps }: Props): React.ReactElement {
  const system = useContext(SystemContext);
  const template = Template(system.language);

  return (
    <div className="flex flex-col mt-[16px] space-y-[16px] w-full">
      <div className="align-center flex justify-center w-full">
        <label className="font-[600] text-[18px]">
          {template.campaign.sessions.howToParticipate}
        </label>
      </div>
      <div className="flex justify-between w-full">
        <Step icon={icons.cardVerify} name={steps.addCard} />
        <img className="mb-[3em]" src={icons.arrowRight} />
        <Step icon={icons.reportReady} name={steps.enroll} />
        <img className="mb-[3em]" src={icons.arrowRight} />
        <Step icon={icons.gift} name={steps.pay} />
      </div>
      <div className="flex flex-col w-full">
        {description.map((text, i) => (
          <div className="flex" key={i}>
            <label className="font-[400] text-[14px]">•</label>
            <label className="font-[400] text-[14px]">{text}</label>
          </div>
        ))}
      </div>
    </div>
  );
}
