import { Flow } from "src/contexts/navigation/context";

export function getFlowByIndex(index: number): Flow {
  switch (index) {
    case 0:
      return Flow.REQUEST_PAN;
    case 1:
      return Flow.SELECT_PAN;
    default:
      return Flow.REQUEST_PAN;
  }
}
