export const testIDs = {
  assistant: {
    id: "v-assistant",
  },
  name: {
    id: "va-title",
  },
  role: {
    id: "va-subtitle",
  },
  icon: {
    id: "va-star",
  },
};

export const altTexts = {
  hero: "Visa logo",
  icon: "star icon",
};

export const defaults = {
  rating: {
    upper: 5,
    lower: 0,
  },
};
