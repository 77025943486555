import React, { useContext } from "react";
import { Context as SystemContext } from "src/contexts/system/context";

export interface Props {
  text: string;
  icon: string;
  onPress?: () => void;
}

export default function Selection({ onPress, text, icon }: Props): React.ReactElement {
  const system = useContext(SystemContext);
  return (
    <button
      className={`${
        system.language === "zh" ? "font-semibold" : "font-medium"
      } text-sm leading-[18px] tracking-[0.25px] gap-2 items-center px-3 py-2 flex bg-white border border-primary border-solid rounded-[50px] text-primary`}
      onClick={onPress}>
      <img
        className={`${system.language === "zh" ? "w-6 h-6" : "w-4 h-4"}`}
        src={icon}
        alt={text}></img>
      {text}
    </button>
  );
}
