export const PANFormat = {
  length: {
    display: 19,
    value: 16,
  },
  segment: {
    count: 4,
    length: 4,
  },
  separator: {
    positions: [4, 9, 14],
    text: " ",
  },
};

export const regex = {
  digit: /\d/g,
  nonDigit: /\D/g,
  timeRange: {
    to: /\d{2}:\d{2} to \d{2}:\d{2}/g,
  },
};
