import { Language } from "src/contexts/system/context";
import { BankCard } from "../AssistantBankCard/constants";
import { getBankCard } from "../AssistantBankCard/viewModel";

export function getBankCards(last4DigitsPans: string[], language: Language): BankCard[] {
  return last4DigitsPans.map(function (pan) {
    const card: BankCard = getBankCard(language);
    const fullPan = "1234 1234 1234 " + pan;
    return { ...card, pan: fullPan };
  });
}
