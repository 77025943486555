import React, { Dispatch, SetStateAction, useContext, useState } from "react";

import { mockPANs } from "src/backend/constants";
import { Context as SystemContext } from "src/contexts/system/context";
import { getMaskedCardNumber } from "src/utilities";
import AssistantBankCard from "../AssistantBankCard";
import { BankCard } from "../AssistantBankCard/constants";
import { getBankCards } from "./viewModel";

export interface Props {
  setSelectedPan: Dispatch<SetStateAction<string | undefined>>;
}

export default function AssistantBankCardList({ setSelectedPan }: Props): React.ReactElement {
  const system = useContext(SystemContext);
  const [bankCards, setBankCards] = useState<BankCard[]>(getBankCards(mockPANs, system.language));
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const onClickHandler = (index: number) => {
    if (selectedIndex >= 0) {
      bankCards[selectedIndex].checked = false;
    }
    bankCards[index].checked = true;
    setSelectedIndex(index);
    setBankCards([...bankCards]);
    const number = getMaskedCardNumber(bankCards[index].pan);
    setSelectedPan(number);
  };
  return (
    <>
      {bankCards.map((card, index) => {
        return (
          <AssistantBankCard
            key={index}
            onClickHandler={onClickHandler}
            index={index}
            bankCard={card}
          />
        );
      })}
    </>
  );
}
