import React, { useContext } from "react";

import Modal from "src/components/Modal";
import { Context as NavigationContext, Flow } from "src/contexts/navigation/context";
import { Location, Context as RestaurantContext, TYPES } from "src/contexts/restaurant/context";
import { Context as SystemContext } from "src/contexts/system/context";
import Template from "src/templates";
import MapOption from "./MapOption";
import "./style.css";
import { getMaps, MapLink } from "./viewModel";

export interface Props {
  back?: Flow;
}

export function renderMaps(maps: MapLink[], location: Location): React.ReactElement[] {
  return maps.map((map, i) => (
    <MapOption
      text={map.map}
      key={i}
      onPress={() => {
        let url;
        if (map.link.includes("baidu")) {
          //Baidu Map doesn't support negative latitude and longitude.
          // There is slightly difference after conversion
          url = `${map.link}${
            location.latitude > 0 ? location.latitude : 360 + location.latitude
          },${location.longitude > 0 ? location.longitude : 360 + location.longitude}&title=${
            location.address
          }`;
        } else if (map.link.includes("qq")) {
          url = `${map.link}${location.latitude},${location.longitude};title:${location.address}`;
        } else if (map.link.includes("apple")) {
          url = `${map.link}${location.latitude},${location.longitude}&address=${location.address}`;
        } else {
          url = `${map.link}${location.latitude},${location.longitude},15z?q=${location.address}`;
        }
        window.open(url, "_blank");
      }}
    />
  ));
}

function renderOptions(options: string[]): React.ReactElement[] {
  return options.map((option, i) => <MapOption text={option} key={i} />);
}

function renderModal(
  title: string,
  options: string[] | MapLink[],
  closeHandler: () => void,
  address?: Location
): React.ReactElement {
  return (
    <Modal title={title} closeHandler={closeHandler}>
      <div className="flex flex-col items-center gap-2 pb-2">
        <div>
          {address ? renderMaps(options as MapLink[], address) : renderOptions(options as string[])}
        </div>
      </div>
    </Modal>
  );
}

export default function NavigationModal({ back }: Props): React.ReactElement {
  const navigation = useContext(NavigationContext);
  const restaurant = useContext(RestaurantContext);
  const system = useContext(SystemContext);
  const maps = getMaps(system.language);
  const template = Template(system.language);

  let backPath = Flow.RECOMMENDATIONS;

  if (back) {
    backPath = back;
  }

  switch (restaurant.type) {
    case TYPES.WEBSITE:
      return renderModal(template.navigationModalHeaders.website, [restaurant.url], () =>
        navigation.update({ ...navigation, flow: backPath })
      );

    case TYPES.PHONE:
      return renderModal(template.navigationModalHeaders.contact, [restaurant.phone], () =>
        navigation.update({ ...navigation, flow: backPath })
      );

    case TYPES.MAPS:
      return renderModal(
        template.navigationModalHeaders.address,
        maps,
        () => navigation.update({ ...navigation, flow: backPath }),
        restaurant.location
      );

    default:
      return renderModal("", [""], () => navigation.update({ ...navigation, flow: backPath }));
  }
}
