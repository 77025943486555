import React from "react";
import { getChatDate } from "src/utilities/index";
import "./style.css";

export interface Props {
  date?: Date;
  message: string;
}

export default function AssistantChatBubble({ date, message }: Props): React.ReactElement {
  return (
    <div className="flex flex-col gap-[12px]">
      <label className="font-[400] text-[12px] text-black">{getChatDate(date ?? new Date())}</label>
      <div className="assistantChatBubble bg-white flex flex-col px-[24px] py-[12px] rounded-br-[24px] rounded-t-[24px] shadow-[0_4px_20px_rgba(0,0,0,0.1)]">
        <label className={`font-[400] leading-[21px] text-[14px] text-black"`}>{message}</label>
      </div>
    </div>
  );
}
