import React from "react";
import { Rewards } from "../constants";

export interface Props {
  card: Rewards;
  index: number;
  onClickHandler: (index: number) => void;
}

export default function GiftCard({ onClickHandler, card, index }: Props): React.ReactElement {
  return (
    <div className="giftCard flex flex-row h-[4rem] items-center py-[2.25rem]">
      <div>
        <img src={card.logo} alt="Gift Card Logo" />
      </div>
      <div className="flex flex-row w-full justify-between">
        <div className="pl-[0.625rem] pr-[1.5rem]">
          <p>{card.title}</p>
        </div>
        <div className="pr-[0.5rem]">
          <input
            className="radio"
            type="radio"
            checked={card.checked}
            onChange={() => onClickHandler(index)}
          />
        </div>
      </div>
    </div>
  );
}
