import React, { useContext, useState } from "react";

import { BankCard } from "src/components/AssistantBankCard/constants";
import ChatHeader from "src/components/ChatHeader";
import ConfirmButton from "src/components/Carousal/OptionButton";
import { Context as EnrollmentContext } from "src/contexts/enrollment/context";
import EnrollmentBankCardList from "src/components/EnrollmentBankCardList";
import { Context as NavigationContext, Flow } from "src/contexts/navigation/context";
import { Context as SystemContext } from "src/contexts/system/context";
import Template from "src/templates";
import "./style.css";

export default function EnrollmentSelectCard(): React.ReactElement {
  const navigation = useContext(NavigationContext);
  const enrollment = useContext(EnrollmentContext);
  const system = useContext(SystemContext);
  const template = Template(system.language);
  const [selectCard, setSelectCard] = useState<BankCard[]>();
  const [styling, setStyling] = useState(
    "campaign absolute bg-[white] h-[100%] slide-in top-0 w-full z-[8200]"
  );

  function backHandler() {
    if (selectCard) {
      enrollment.update({ ...enrollment, selectedCard: undefined });
    }
    setStyling("campaign absolute bg-[white] h-[100%] slide-out top-0 w-full z-[8200]");
    setTimeout(
      () => navigation.update({ ...navigation, flow: Flow.ENROLLMENT_SELECT_REWARD }),
      500
    );
  }
  return (
    <div className={styling}>
      <ChatHeader title={template.enrollment.enrollment} backHandler={backHandler} />
      <div className="flex flex-col h-[calc(100%-4rem)] justify-between px-[1.5rem] pt-[2rem]">
        <EnrollmentBankCardList setSelectedCard={setSelectCard} />
        <div className="flex justify-center py-[1.5rem]">
          <ConfirmButton
            disabled={selectCard === undefined}
            selected
            sizing="large"
            handler={() => {
              enrollment.update({ ...enrollment, selectedCard: selectCard });
              navigation.update({ ...navigation, flow: Flow.ENROLLMENT_SUCCESS });
            }}>
            {template.actions.confirm}
          </ConfirmButton>
        </div>
      </div>
    </div>
  );
}
