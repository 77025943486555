import { Language } from "src/configurations";
import { MessageType } from "src/renderer/MessageType";
import Template from "src/templates";

import Message from "../Message";

export function getMessage(language: Language): Message<unknown>[] {
  const template = Template(language);
  return [
    {
      content: {
        feedbacks: [
          template.chat.messages.hello,
          template.chat.messages.niceToMeetYou,
          template.chat.messages.gladToKnowYou,
        ],
        message: template.chat.messages.greeting,
      },
      type: MessageType.GREET,
    },
    {
      content: {
        message: template.chat.messages.giveMeYourVisaCardNumber,
        response: template.chat.messages.iHaveAddedVisaCard,
        options: [
          { text: template.chat.messages.addVisaCard },
          { text: template.chat.messages.chooseYourVisaCard },
        ],
      },
      type: MessageType.CARD_SELECTION,
    },
    {
      content: {
        label: template.chat.messages.destination,
        message: {
          noDestination: template.chat.messages.pleaseSelectDestination,
          hasDestination: template.chat.messages.letMeProvideYouTheCrossBorderServices,
        },
      },
      type: MessageType.DESTINATION_SELECTION,
    },
    {
      content: {
        options: [
          template.collectFeedback.excellent,
          template.collectFeedback.good,
          template.collectFeedback.needImprovement,
        ],
        message: template.collectFeedback.askForFeedback,
      },
      type: MessageType.COLLECT_FEEDBACK,
    },
    {
      content: {
        message: template.collectFeedback.thanksLetter,
      },
      type: MessageType.END,
    },
  ];
}
