import React from "react";

import { getChatDate, getMaskedCardNumber } from "src/utilities";
import "./style.css";

export interface Props {
  date?: Date;
  pan: string;
  template: string;
}

export default function UserConfirmedPANChatBubble({
  date,
  pan,
  template,
}: Props): React.ReactElement {
  return (
    <div className="flex flex-col gap-[12px] items-end">
      <div className="flex flex-col">
        <label className="font-[400] leading-[16px] text-[12px] text-black">
          {getChatDate(date ?? new Date())}
        </label>
      </div>
      <div className="bg-primary flex flex-row max-w-[90%] px-[24px] py-[12px] rounded-bl-[24px] rounded-t-[24px] shadow-[0_4px_20px_rgba(0,0,0,0.1)]">
        <span className="font-[400] non-chinese text-[16px] text-white text-right">
          {template.replace("{number}", getMaskedCardNumber(pan))}
        </span>
      </div>
    </div>
  );
}
