import React, { useContext } from "react";

import { icons } from "src/assets";
import { Context as NavigationContext, Flow } from "src/contexts/navigation/context";
import { Context as ChatContext } from "src/contexts/chat/context";
import { Context as PreferencesContext } from "src/contexts/preferences/context";
import { Context as SystemContext } from "src/contexts/system/context";
import { MessageType } from "src/renderer/MessageType";
import Template from "src/templates";
import { getDestinationName } from "src/utilities";
import BubbleWithDropDownButtonSelection from "../BubbleWithDropDownButtonSelection";

export interface Props {
  date?: Date;
  label: string;
  message: {
    noDestination: string;
    hasDestination: string;
  };
}
export default function DestinationSelection({ date, label, message }: Props): React.ReactElement {
  const navigation = useContext(NavigationContext);
  const chat = useContext(ChatContext);
  const preferences = useContext(PreferencesContext);
  const system = useContext(SystemContext);
  const template = Template(system.language);

  return (
    <div className="h-[50%] w-[100%] flex flex-col flex-grow justify-between">
      <BubbleWithDropDownButtonSelection
        date={date}
        message={message}
        dropDownProps={{
          message: label,
          placeholder: template.chat.messages.pleaseSelect,
          value: getDestinationName(
            template,
            template.chat.messages.pleaseSelect,
            preferences.destination
          ),
          onPress:
            chat.current < MessageType.COLLECT_FEEDBACK
              ? () => navigation.update({ ...navigation, flow: Flow.REQUEST_DESTINATION })
              : () => null,
        }}
        buttonOptions={[
          {
            text: template.serviceSelectButtonLabels.restaurant,
            icon: icons.restaurant,
            onPress:
              chat.current <= MessageType.COLLECT_FEEDBACK
                ? () => navigation.update({ ...navigation, flow: Flow.RECOMMENDATIONS })
                : () => null,
          },
          {
            text: template.serviceSelectButtonLabels.accommodation,
            icon: icons.ticket,
          },
          {
            text: template.serviceSelectButtonLabels.catering,
            icon: icons.catering,
          },
          {
            text: template.serviceSelectButtonLabels.airTicket,
            icon: icons.airplane,
          },
          {
            text: template.serviceSelectButtonLabels.shopping,
            icon: icons.cart,
          },
          {
            text: template.serviceSelectButtonLabels.simcard,
            icon: icons.simData,
          },
          {
            text: template.serviceSelectButtonLabels.carRental,
            icon: icons.car,
          },
          {
            text: template.serviceSelectButtonLabels.venueTickets,
            icon: icons.hotel,
          },
          {
            text: template.serviceSelectButtonLabels.apps,
            icon: icons.mobileApp,
          },
          {
            text: template.serviceSelectButtonLabels.entertainment,
            icon: icons.entertainment,
          },
        ]}></BubbleWithDropDownButtonSelection>
    </div>
  );
}
