import React, { useContext, useState } from "react";

import { images } from "src/assets";
import { Context as NavigationContext, Flow } from "src/contexts/navigation/context";
import { Context as SystemContext } from "src/contexts/system/context";
import Header from "src/components/Header";
import Template from "src/templates";
import HowToParticipate from "./HowToParticipate";
import Introduction from "./Introduction";
import "./style.css";
import Title from "./Title";

export default function CampaignDetails(): React.ReactElement {
  const navigation = useContext(NavigationContext);
  const system = useContext(SystemContext);
  const template = Template(system.language);
  const [styling, setStyling] = useState(
    "absolute bg-[white] h-[100%] slide-in top-0 w-full z-[8000]"
  );
  const backHandler = () => {
    setStyling("absolute bg-[white] h-[100%] slide-out top-0 w-full z-[8000]");
    setTimeout(() => navigation.update({ ...navigation, flow: Flow.RECOMMENDATIONS }), 500);
  };

  const onEnroll = () => {
    navigation.update({ ...navigation, flow: Flow.ENROLLMENT_SELECT_REWARD });
  };

  return (
    <div className={styling}>
      <Header backHandler={backHandler} title={template.campaign.header} />
      <div className="campgain flex flex-col overflow-y-scroll gap-6 pb-[13px] h-[calc(100%-8rem)]">
        <img alt={images.campaign} className="w-full" src={images.campaign} />
        <div className="flex flex-col px-[24px]">
          <Title {...template.campaign.title} />
          <Introduction {...template.campaign.introduction} />
          <HowToParticipate {...template.campaign.howToParticipate} />
        </div>
      </div>
      <div className="mb-[16px] mt-[8px] px-[24px] h-[4rem]">
        <button className="enroll-button" onClick={onEnroll}>
          {template.actions.enroll}
        </button>
      </div>
    </div>
  );
}
