import { ICognitoStorage } from "amazon-cognito-identity-js";

const memoryStorage: { [key: string]: string } = {};

function clear() {
  Object.keys(memoryStorage).forEach(function (key) {
    delete memoryStorage[key];
  });
}

function getItem(key: string) {
  return Object.hasOwn(memoryStorage, key) ? memoryStorage[key] : null;
}

function removeItem(key: string) {
  delete memoryStorage[key];
}

function setItem(key: string, value: string) {
  memoryStorage[key] = value;
}

export function getAccessToken() {
  const accessToken = Object.keys(memoryStorage).find((key) => key.endsWith("accessToken"));
  if (accessToken) {
    return memoryStorage[accessToken];
  }
  return "";
}

export const authStorage: ICognitoStorage = {
  clear,
  getItem,
  removeItem,
  setItem,
};
