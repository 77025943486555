import { Language } from "src/configurations";
import { ContextType as ChatContext } from "src/contexts/chat/context";
import { ContextType as NavigationContext, Flow } from "src/contexts/navigation/context";
import { ContextType as PreferencesContext, Location } from "src/contexts/preferences/context";
import Template, { City, TemplateDetails } from "src/templates";
import { LocationList } from "./model";
import { MessageType } from "src/renderer/MessageType";

export function getCities(template: TemplateDetails): [City] {
  return Object.keys(template.cities) as [City];
}

export function getLocationList(language: Language): LocationList {
  const englishTemplate = Template("en");
  const mandarinTemplate = Template("zh");

  switch (language) {
    case "en":
      return {
        losAngeles: [
          {
            en: englishTemplate.locations.hollywood,
            id: "hollywood",
            zh: "",
          },
          {
            en: englishTemplate.locations.beverlyHills,
            id: "beverlyHills",
            zh: "",
          },
          {
            en: englishTemplate.locations.universalStudios,
            id: "universalStudios",
            zh: "",
          },
          {
            en: englishTemplate.locations.chinatown,
            id: "chinatown",
            zh: "",
          },
        ],
        singapore: [
          {
            en: englishTemplate.locations.visaRobinsonRoad,
            id: "visaRobinsonRoad",
            zh: "",
          },
          {
            en: englishTemplate.locations.seaAquarium,
            id: "seaAquarium",
            zh: "",
          },
          {
            en: englishTemplate.locations.merlion,
            id: "merlion",
            zh: "",
          },
          {
            en: englishTemplate.locations.universalStudiosSingapore,
            id: "universalStudiosSingapore",
            zh: "",
          },
        ],
      };
    case "zh":
      return {
        losAngeles: [
          {
            en: englishTemplate.locations.hollywood,
            id: "hollywood",
            zh: mandarinTemplate.locations.hollywood,
          },
          {
            en: englishTemplate.locations.beverlyHills,
            id: "beverlyHills",
            zh: mandarinTemplate.locations.beverlyHills,
          },
          {
            en: englishTemplate.locations.universalStudios,
            id: "universalStudios",
            zh: mandarinTemplate.locations.universalStudios,
          },
          {
            en: englishTemplate.locations.chinatown,
            id: "chinatown",
            zh: mandarinTemplate.locations.chinatown,
          },
        ],
        singapore: [
          {
            en: englishTemplate.locations.visaRobinsonRoad,
            id: "visaRobinsonRoad",
            zh: mandarinTemplate.locations.visaRobinsonRoad,
          },
          {
            en: englishTemplate.locations.seaAquarium,
            id: "seaAquarium",
            zh: mandarinTemplate.locations.seaAquarium,
          },
          {
            en: englishTemplate.locations.merlion,
            id: "merlion",
            zh: mandarinTemplate.locations.merlion,
          },
          {
            en: englishTemplate.locations.universalStudiosSingapore,
            id: "universalStudiosSingapore",
            zh: mandarinTemplate.locations.universalStudiosSingapore,
          },
        ],
      };
  }
}

export function onBack(navigation: NavigationContext) {
  switch (navigation.flow) {
    case Flow.REQUEST_DESTINATION:
      navigation.update({ ...navigation, flow: Flow.CHAT });
      break;
    case Flow.REQUEST_DESTINATION_RECOMMENDATIONS:
      navigation.update({ ...navigation, flow: Flow.RECOMMENDATIONS });
      break;
    default:
      break;
  }
}

export function onSubmit(
  chat: ChatContext,
  preferences: PreferencesContext,
  city: City,
  location?: Location
) {
  if (location) {
    preferences.update({
      ...preferences,
      destination: { city, location },
    });
    if (chat.current === MessageType.DESTINATION_SELECTION) {
      setTimeout(() => chat.update({ ...chat, current: chat.current + 0.5 }), 500);
    }
  }
}
