import React from "react";

import "./style.css";

export interface Props {
  description: string;
  name: string;
}

export default function Title({ description, name }: Props): React.ReactElement {
  return (
    <div className="flex flex-col items-center mt-[20px] space-y-[16px] w-full">
      <label className="font-[600] text-[22px]">{name}</label>
      <label className="font-[400] text-[16px]">{description}</label>
    </div>
  );
}
