import { Language } from "src/configurations";
import Message from "../Message";
import { getMessage } from "./model";

export default class Chatbot {
  private dates: Date[] = [];

  chat(language: Language, current: number): Message<unknown>[] {
    if (this.dates.length === current - 1) {
      this.dates.push(new Date());
    }
    return getMessage(language)
      .slice(0, current)
      .map((message, i) => ({ ...message, date: this.dates[i] }));
  }

  reset() {
    this.dates = [];
  }
}

export const chatbot = new Chatbot();
