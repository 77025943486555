import { Language } from "src/configurations";
import en from "./en.json";
import zh from "./zh.json";

interface Cities {
  losAngeles: string;
  singapore: string;
}

interface Locations {
  beverlyHills: string;
  chinatown: string;
  hollywood: string;
  merlion: string;
  seaAquarium: string;
  universalStudios: string;
  universalStudiosSingapore: string;
  visaRobinsonRoad: string;
}

export type City = keyof Cities;

export type Location = keyof Locations;

export interface TemplateDetails {
  access: {
    confirmPassword: string;
    email: string;
    error: {
      credentials: string;
      otp: string;
    };
    firstName: string;
    getStarted: string;
    header: string;
    lastName: string;
    login: string;
    mfaRequest: string;
    password: string;
    register: string;
    username: string;
    languageSelection: {
      en: string;
      zh: string;
    };
  };
  actions: {
    confirm: string;
    done: string;
    enroll: string;
    next: string;
    nextStep: string;
    addCard: string;
  };
  campaign: {
    details: {
      acceptanceMerchants: {
        details: {
          food: {
            description: string;
            title: string;
          };
          shopping: {
            description: string;
            title: string;
          };
          subscription: {
            description: string;
            title: string;
          };
          transportation: {
            description: string;
            title: string;
          };
        };
        title: string;
      };
      availableCard: {
        description: string;
        title: string;
      };
      campaignTime: {
        description: string;
        title: string;
      };
    };
    header: string;
    howToParticipate: {
      description: string[];
      steps: {
        addCard: string;
        enroll: string;
        pay: string;
      };
    };
    introduction: {
      description: string;
      title: string;
    };
    sessions: {
      howToParticipate: string;
      introduction: string;
    };
    title: {
      description: string;
      name: string;
    };
  };
  chat: {
    header: string;
    messages: {
      chooseYourVisaCard: string;
      destination: string;
      giveMeYourVisaCardNumber: string;
      gladToKnowYou: string;
      greeting: string;
      hello: string;
      addVisaCard: string;
      pleaseSelectDestination: string;
      letMeProvideYouTheCrossBorderServices: string;
      niceToMeetYou: string;
      pleaseSelect: string;
      iHaveAddedVisaCard: string;
    };
    profile: {
      name: string;
      role: string;
    };
  };
  cities: Cities;
  collectFeedback: {
    askForFeedback: string;
    excellent: string;
    good: string;
    needImprovement: string;
    thanksLetter: string;
  };
  languages: {
    en: string;
    zh: string;
  };
  locations: Locations;
  navigationApp: {
    appleMap: string;
    baiduMap: string;
    googleMap: string;
    tencentMap: string;
  };
  navigationModalHeaders: {
    address: string;
    contact: string;
    website: string;
  };
  preferences: {
    cuisine: string;
    distances: string;
    filters: string;
    food: {
      options: {
        all: string;
        chinese: string;
        japanese: string;
        mexican: string;
        sea: string;
        western: string;
      };
      title: string;
    };
    submission: string;
    reset: string;
    title: string;
  };
  requestDestination: {
    dropdown: {
      title: string;
    };
    header: string;
    popup: {
      placeholder: string;
    };
  };
  requestPAN: {
    disclaimer: string;
    header: string;
    headerChoice: string;
    inputLabel: string;
    title: string;
    terms1: string;
    terms2: string;
    terms3: string;
    terms4: string;
    terms5: string;
    tips: string;
    tipsHeader: string;
    tips1: string;
    tips2: string;
    tips3: string;
    tips4: string;
  };
  restaurantChatCard: {
    buttonsText: {
      call: string;
      navigation: string;
      website: string;
    };
    isOpen: {
      open: string;
      closed: string;
    };
    details: {
      quote: string;
      categories: {
        wine: string;
        bar: string;
      };
    };
  };
  restaurantDetails: {
    header: string;
    information: {
      bill: string;
      address: string;
      reservation: string;
      atmosphere: string;
      service: string;
      payment: string;
    };
    details: {
      website: string;
      specialty: string;
      schedule: string;
      todayOpen: string;
      open: string;
      contact: string;
      close: string;
      localTime: string;
    };
  };
  restaurantRecommendations: {
    chooseYourPreference: string;
    diningDestination: string;
    header: string;
    recommendAnotherBatch: string;
    defaultCampaign: string;
  };
  serviceSelectButtonLabels: {
    accommodation: string;
    airTicket: string;
    apps: string;
    carRental: string;
    catering: string;
    entertainment: string;
    restaurant: string;
    shopping: string;
    simcard: string;
    venueTickets: string;
  };
  enrollment: {
    enrollment: string;
    selectReward: string;
    chooseReward: string;
    visaXBCampaign: string;
    appleGiftCard: string;
    googleGiftCard: string;
    starbucksGiftCard: string;
    netflixGiftCard: string;
    airbnbCoupon: string;
    agodaCoupon: string;
    chooseVisaCard: string;
    all: string;
    success: string;
    congratulation: string;
  };
  bank: {
    fdnb: string;
  };
  weekdays: string[];
}

export const template = Template();

// TODO: switch languages based on the env variable
export default function Template(language?: Language): TemplateDetails {
  const option = language ?? "zh";
  switch (option) {
    case "en":
      return en;
    case "zh":
      return zh;
  }
}
