import React, { useContext } from "react";

import { icons, images } from "src/assets";
import { Context as NavigationContext, Flow } from "src/contexts/navigation/context";
import { Context as SystemContext } from "src/contexts/system/context";
import { signIn } from "src/contexts/auth";
import Template from "src/templates";
import "./style.css";
import { getAccessToken } from "src/utilities/amplifyStorage";

export default function LandingPage(): React.ReactElement {
  const navigation = useContext(NavigationContext);
  const system = useContext(SystemContext);
  const template = Template(system.language);
  let engButtonStyle, zhButtonStyle;
  switch (system.language) {
    case "en": {
      engButtonStyle = "underline leading-6 text-base font-medium text-primary pt-7";
      zhButtonStyle = "underline leading-6 text-base font-medium text-[rgba(20,34,57,0.4)] pt-7";
      break;
    }
    case "zh": {
      engButtonStyle = "underline leading-6 text-base font-medium text-[rgba(20,34,57,0.4)] pt-7";
      zhButtonStyle = "underline leading-6 text-base font-medium text-primary pt-7";
      break;
    }
  }
  return (
    <div className="pageBody bg-background flex flex-col">
      <div className="w-full h-[50%] bg-lightBlue rounded-b-[100px] flex justify-center">
        <img src={images.visa.landing} />
      </div>
      <div className="flex flex-col items-center -translate-y-[100px] h-full">
        <img src={images.visa.landingLogo} className="max-w-[200px] " />
        <label className="font-semibold text-[24px] text-center text-black pt-7">
          {template.access.header}
        </label>
        <button
          className="landingButton bg-primary text-white rounded-[2000px] mt-6 mb-3"
          onClick={() => {
            if (getAccessToken()) {
              navigation.update({ ...navigation, flow: Flow.CHAT });
            } else {
              signIn();
            }
          }}>
          {template.access.getStarted}
        </button>
        <div className="flex">
          <button
            className={engButtonStyle}
            onClick={() => system.update({ ...system, language: "en" })}>
            {template.access.languageSelection.en}
          </button>
          <img src={icons.line} className="pt-7 px-6"></img>
          <button
            className={zhButtonStyle}
            onClick={() => system.update({ ...system, language: "zh" })}>
            {template.access.languageSelection.zh}
          </button>
        </div>
      </div>
    </div>
  );
}
