import React from "react";

import AssistantChatBubble, { Props as ChatBubbleProps } from "src/components/AssistantChatBubble";
import CardSelection, { Props as CardSelectionProps } from "src/components/CardSelection";
import CollectFeedback, { Props as CollectFeedbackProps } from "src/components/CollectFeedback";
import Greeting, { Props as GreetProps } from "src/components/Greeting";
import DestinationSelection, {
  Props as DestinationSelectionProps,
} from "src/components/DestinationSelection";
import Message from "src/models/Message";
import { MessageType } from "../MessageType";

export function render<T>(payload: Message<T>, index: number): React.ReactElement {
  switch (payload.type) {
    case MessageType.CARD_SELECTION:
      return (
        <CardSelection
          {...(payload.content as CardSelectionProps)}
          date={payload.date}
          key={index}
        />
      );
    case MessageType.GREET:
      return <Greeting {...(payload.content as GreetProps)} date={payload.date} key={index} />;
    case MessageType.DESTINATION_SELECTION:
      return (
        <DestinationSelection
          {...(payload.content as DestinationSelectionProps)}
          date={payload.date}
          key={index}
        />
      );
    case MessageType.COLLECT_FEEDBACK:
      return (
        <CollectFeedback
          {...(payload.content as CollectFeedbackProps)}
          date={payload.date}
          key={index}
        />
      );
    case MessageType.END:
      return (
        <div className="h-[50%] w-[100%] flex flex-col flex-grow justify-between" key={index}>
          <AssistantChatBubble {...(payload.content as ChatBubbleProps)} date={payload.date} />
        </div>
      );
    default:
      return <div key={index} />;
  }
}
