import React, { Dispatch, SetStateAction, useContext, useState } from "react";

import { mockPANs } from "src/backend/constants";
import { Context as SystemContext } from "src/contexts/system/context";
import AssistantBankCard from "../AssistantBankCard";
import { getBankCards } from "../AssistantBankCardList/viewModel";
import { BankCard } from "../AssistantBankCard/constants";
import "./style.css";
import Template from "src/templates";

export interface Props {
  setSelectedCard: Dispatch<SetStateAction<BankCard[] | undefined>>;
}

export default function EnrollmentBankCardList({ setSelectedCard }: Props): React.ReactElement {
  const system = useContext(SystemContext);
  const template = Template(system.language);
  const [bankCards, setBankCards] = useState<BankCard[]>(getBankCards(mockPANs, system.language));
  const [selectAll, setSelectAll] = useState(false);
  const onClickHandler = (index: number) => {
    bankCards[index].checked = !bankCards[index].checked;
    setBankCards([...bankCards]);
    const filtedList = bankCards.filter((card) => card.checked === true);
    setSelectedCard(filtedList);
  };
  const onClickAllHandler = () => {
    bankCards.map((card) => {
      card.checked = !selectAll;
    });
    setBankCards([...bankCards]);
    setSelectAll(!selectAll);
    !selectAll ? setSelectedCard(bankCards) : setSelectedCard(undefined);
  };
  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between pb-[1rem]">
        <div className="text-[1.375rem] font-semibold">{template.enrollment.chooseVisaCard}</div>
        <div className="rightColumn">
          <input
            className="checkbox"
            type="checkbox"
            checked={selectAll}
            onChange={onClickAllHandler}
          />
          <label className="text-[0.75rem] pl-[0.75rem]">{template.enrollment.all}</label>
        </div>
      </div>
      {bankCards.map((card, index) => {
        return (
          <AssistantBankCard
            key={index}
            onClickHandler={onClickHandler}
            index={index}
            bankCard={card}
            selectType={"checkbox"}
          />
        );
      })}
    </div>
  );
}
